// ----- Modules ----- //
import React, { useMemo, useState } from "react";
// ----- MUI ----- //
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
// ----- Icons ----- //
import FlagIcon from '@mui/icons-material/Flag';
import GppGoodIcon from "@mui/icons-material/GppGood";
import RetryIcon from '@mui/icons-material/PublishedWithChanges';
// ----- Utils ----- //
import { useConfiguredAxios } from "../../../utils/AxiosInstance";
import { TransactionType } from "../../../utils/Types";
import { useSnackbar } from "notistack";

// ----- Types ----- //
interface ActionButtonsProps {
  transaction: TransactionType;
}

export enum ActionType {
  FLAG,
  VERIFY,
}

// ----- Component ----- //
const ActionButtons: React.FC<ActionButtonsProps> = ({transaction}) => {
  const axiosInstance = useConfiguredAxios();
  const {enqueueSnackbar} = useSnackbar();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentActionType, setCurrentActionType] = useState<ActionType>(ActionType.VERIFY);
  const [loading, setLoading] = useState({flag: false, verify: false, autoVerify: false});
  const [error, setError] = useState<string | null>(null);

  const handleDialogOpen = (type: ActionType) => {
    setCurrentActionType(type);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setError(null);
  };

  const handleAction = async (url: string, successMessage: string, actionType: keyof typeof loading) => {
    setLoading((prev) => ({...prev, [actionType]: true}));
    setError(null);
    try {
      await axiosInstance.put(url);
      enqueueSnackbar(successMessage, {variant: "success"});
      handleDialogClose();
    } catch (error: any) {
      const errorMessage = error.response?.data || "An unexpected error occurred";
      setError(errorMessage);
      enqueueSnackbar("Error: " + errorMessage, {variant: "error"});
    } finally {
      setLoading((prev) => ({...prev, [actionType]: false}));
    }
  };

  const handleFlagAction = () => handleAction(`/api/transactions/flag/${transaction.uuid}`, "Transaction flagged successfully", 'flag');
  const handleVerifyAction = () => handleAction(`/api/transactions/verify/${transaction.uuid}`, "Transaction verified successfully", 'verify');

  const handleAutoVerifyAction = async () => {
    setLoading((prev) => ({...prev, autoVerify: true}));
    setError(null);
    try {
      const response = await axiosInstance.put(`/api/transactions/autoverify/${transaction.uuid}`);
      if (response.data) {
        enqueueSnackbar("Transaction auto-verified successfully", {variant: "success"});
      } else {
        enqueueSnackbar("No associated payment found for this transaction", {
          variant: "warning",
          preventDuplicate: false
        });
      }
    } catch (error: any) {
      const errorMessage = error.response?.data || "An unexpected error occurred";
      setError(errorMessage);
      enqueueSnackbar("Error: " + errorMessage, {variant: "error"});
    } finally {
      setLoading((prev) => ({...prev, autoVerify: false}));
    }
  };

  const handleConfirm = async () => {
    if (currentActionType === ActionType.FLAG) {
      await handleFlagAction();
    } else {
      await handleVerifyAction();
    }
  };

  const dialogTitle = useMemo(
    () => (currentActionType === ActionType.FLAG ? "Flag transaction?" : "Accept transaction?"),
    [currentActionType]
  );

  const dialogContentText = useMemo(
    () =>
      currentActionType === ActionType.FLAG
        ? "Transaction will be marked as flagged. Indicating that there is an issue with it and it needs to be reviewed."
        : "Transaction will be marked as verified. Confirming that everything is correct.",
    [currentActionType]
  );

  const dialogActions = (
    <DialogActions>
      <Box sx={{m: 1, position: "relative"}}>
        <Button variant="text" color="secondary" onClick={handleDialogClose} disabled={loading.flag || loading.verify}>
          Cancel
        </Button>
      </Box>
      <Box sx={{m: 1, position: "relative"}}>
        <Button variant="contained" color="primary" onClick={handleConfirm} disabled={loading.flag || loading.verify}>
          {currentActionType === ActionType.FLAG ? "Flag" : "Accept"}
        </Button>
        {(loading.flag || loading.verify) && (
          <CircularProgress
            size={24}
            sx={{
              color: "primary",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </DialogActions>
  );

  return (
    <Box sx={{display: "flex", gap: 0.5, alignItems: "center", justifyContent: "center", height: "100%"}}>
      {(!transaction.flagged && transaction.tries < 3) ? (
        <Tooltip title="Flag this transaction" disableInteractive arrow placement="top">
          <Box sx={{position: 'relative'}}>
            <IconButton color="error" onClick={() => handleDialogOpen(ActionType.FLAG)} disabled={loading.flag}>
              <FlagIcon/>
            </IconButton>
            {loading.flag && (
              <CircularProgress
                size={24}
                sx={{
                  color: "primary",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title="Retry auto-verification" disableInteractive arrow placement="top">
          <Box sx={{position: 'relative'}}>
            <IconButton color="primary" onClick={handleAutoVerifyAction} disabled={loading.autoVerify}>
              <RetryIcon/>
            </IconButton>
            {loading.autoVerify && (
              <CircularProgress
                size={24}
                sx={{
                  color: "primary",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Tooltip>
      )}

      <Tooltip title="Accept this transaction" disableInteractive arrow placement="top">
        <Box sx={{position: 'relative'}}>
          <IconButton color="success" onClick={() => handleDialogOpen(ActionType.VERIFY)} disabled={loading.verify}>
            <GppGoodIcon/>
          </IconButton>
          {loading.verify && (
            <CircularProgress
              size={24}
              sx={{
                color: "primary",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Tooltip>

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth={"xs"} fullWidth>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
          {error && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        {dialogActions}
      </Dialog>
    </Box>
  );
};

export default ActionButtons;
