// ----- Modules ----- //
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
// ----- Contexts ----- //
import { Stats, TicketsContext } from "../../contexts/TicketsProvider";
// ----- Components ----- //
import { InfoText } from "../../components/Header/Info/InfoText";
// ----- MUI Icons ----- //
import InventoryIcon from '@mui/icons-material/Inventory';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReceiptIcon from '@mui/icons-material/Receipt';

interface TicketsInfoProps {
  direction?: 'row' | 'column';
}

const TicketsInfo: React.FC<TicketsInfoProps> = ({direction = 'row'}) => {
  // ----- Context ----- //
  const {getTicketStats} = useContext(TicketsContext);

  // ----- States ----- //
  const [stats, setStats] = useState<Stats>({inventory: null, sold: null, value: null});

  // ----- Effects ----- //
  useEffect(() => {
    getTicketStats().then(setStats);
  }, [getTicketStats]);

  return (
    <div style={{display: 'flex', flexDirection: direction, gap: '16px'}}>
      <InfoText
        value={stats.inventory}
        valueType="number"
        label="Active tickets"
        icon={<InventoryIcon fontSize="large"/>}
      />
      <InfoText
        value={stats.value}
        valueType="currency"
        label="Total value"
        icon={<PointOfSaleIcon fontSize="large"/>}
      />
      <InfoText
        value={stats.sold}
        valueType="number"
        label={`Sold - ${moment().format('YYYY')}`}
        icon={<ReceiptIcon fontSize="large"/>}
      />
    </div>
  );
};

export default TicketsInfo;
