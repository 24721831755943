// ----- Modules ----- //
import React from "react";
// ----- MUI ----- //
import { Grid } from "@mui/material";
// ----- Components ----- //
import NotVerified from "./components/NotVerified";
import Flagged from "./components/Flagged";

const Transactions: React.FC = () => {
  return (
    <Grid container>
      <NotVerified/>
      <Flagged/>
    </Grid>
  );
};

export default Transactions;
