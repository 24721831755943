import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import CardComponent from "../../../components/CardComponent";
import { TicketType } from "../../../utils/Types";
import { CustomDataGrid } from "../../../utils/Theme";
import moment, { Moment } from "moment";
import DateSelect from "../../../components/Header/DateSelect";

const getRowClassName = (params: any) => (
  params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
);

interface TicketTableProps {
  icon: ReactElement;
  title: string;
  borderColor: string;
  dataFunction: (year: Moment, page: number, pageSize: number) => Promise<{ tickets: TicketType[], total: number }>;
  columns: any;
  loading: boolean;
}

const TicketTable: React.FC<TicketTableProps> = ({
                                                   icon,
                                                   title,
                                                   borderColor,
                                                   dataFunction,
                                                   columns,
                                                   loading,
                                                 }) => {
  const [date, setDate] = useState<moment.Moment>(moment());
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [tickets, setTickets] = useState<TicketType[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const fetchTickets = useCallback(async (currentDate: Moment, page: number, pageSize: number) => {
    const data = await dataFunction(currentDate, page, pageSize);
    setTickets(data.tickets);
    setRowCount(data.total);
  }, [dataFunction]);

  const debouncedGetTickets = useMemo(
    () => debounce(fetchTickets, 300),
    [fetchTickets]
  );

  useEffect(() => {
    debouncedGetTickets(date, paginationModel.page, paginationModel.pageSize);

    return () => {
      debouncedGetTickets.cancel();
    };
  }, [date, paginationModel, debouncedGetTickets]);

  const handlePaginationModelChange = (params: any) => {
    setPaginationModel(params);
  };

  return (
    <CardComponent
      icon={icon}
      title={title}
      header={<DateSelect date={date} setDate={setDate}/>}
      color={borderColor}
      defaultHeight="calc(100vh - 200px)"
    >
      <CustomDataGrid
        getRowId={(row) => row.skybox_id}
        columns={columns}
        rows={tickets}
        density="compact"
        getRowClassName={getRowClassName}
        initialState={{pinnedColumns: {right: ['actions']}}}
        loading={loading}
        pagination
        onPaginationModelChange={handlePaginationModelChange}
        paginationMode="server"
        rowCount={rowCount}
        localeText={{footerTotalRows: 'Total tickets not sold:'}}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
      />
    </CardComponent>
  );
};

export default TicketTable;
