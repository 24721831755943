// ----- Modules ----- //
import React, { useMemo, useState } from "react";
import moment from "moment";
// ----- MUI ----- //
// ----- Components ----- //
import { ConfirmDeleteModal, ConfirmPOModal, EditOrderModal } from "../OrderModal";
import OrderActions from "../OrderAction";
import CardComponent from "../../../../components/CardComponent";
// ----- Utils ----- //
import { CustomDataGrid } from "../../../../utils/Theme";
import { useConfiguredAxios } from "../../../../utils/AxiosInstance";
// ----- Types ----- //
import { Order } from "../../types";


interface MpvOrdersProps {
  rows: Order[];
  loading: boolean;
  fetchOrders: () => void;
}

const valueFormatter = (value: any) => {
  if (!value) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value);
};

const MpvOrders: React.FC<MpvOrdersProps> = ({rows, loading, fetchOrders}) => {
  const axiosInstance = useConfiguredAxios();
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [confirmPOModalOpen, setConfirmPOModalOpen] = useState<boolean>(false);

  const handleEditClick = (order: Order) => {
    setSelectedOrder(order);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (order: Order) => {
    setSelectedOrder(order);
    setDeleteModalOpen(true);
  };

  const handlePOClick = (order: Order) => {
    setSelectedOrder(order);
    setConfirmPOModalOpen(true);
  };

  const handleEditOrder = async (updatedOrder: Order) => {
    try {
      await axiosInstance.put(`/api/orders/mpv/${updatedOrder.id}`, updatedOrder);
      console.log("Updated order:", updatedOrder);
    } catch (error: any) {
      console.error("Error updating order:", error);
    }
    fetchOrders();
  };

  const handleDeleteOrder = async (orderId: string) => {
    try {
      await axiosInstance.delete(`/api/orders/mpv/${orderId}`);
      console.log("Deleted order:", orderId);
    } catch (error: any) {
      console.error("Error deleting order:", error);
    }
    fetchOrders();
  };

  const handlePOOrder = async (orderId: string) => {
    try {
      await axiosInstance.put(`/api/orders/mpv/po/${orderId}`);
      console.log("PO order:", orderId);
    } catch (error: any) {
      console.error("Error processing PO order:", error);
    }
    fetchOrders();
  };

  const columns = useMemo(() => [
    {field: "id", headerName: "ID", width: 150},
    {field: "purchase_id", headerName: "Purchase ID", width: 200},
    {field: "vividseats_id", headerName: "Vividseats ID", width: 125},
    {
      field: "cost", headerName: "Cost", width: 100, valueFormatter,
      filterable: false
    },
    {field: "event_name", headerName: "Event Name", width: 250},
    {field: "venue_name", headerName: "Venue Name", width: 250},
    {
      field: "event_datetime",
      headerName: "Date",
      width: 200,
      valueGetter: (params: any) => moment(params.value).format("MM/DD/YYYY - hh:mm A"),
      filterable: false,
    },
    {field: "section", headerName: "Section", width: 100},
    {field: "quantity", headerName: "Quantity", width: 75, type: 'number'},
    {field: "email", headerName: "Email", width: 250},
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      valueGetter: (params: any) => moment(params.value).format("MM/DD/YYYY"),
      filterable: false,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      valueGetter: (params: any) => moment(params.value).format("MM/DD/YYYY"),
      filterable: false,
    },
    {
      field: "actions",
      headerName: "",
      width: 125,
      renderCell: (params: any) => (
        <OrderActions
          order={params.row}
          onEdit={handleEditClick}
          onDelete={handleDeleteClick}
          onPO={handlePOClick}
        />
      ),
      filterable: false,
    },
  ], [handleEditClick, handleDeleteClick, handlePOClick]);

  return (
    <>
      <CardComponent title="MPV Orders" color="#ba323c" defaultHeight="calc(100vh - 200px)">
        <CustomDataGrid
          // @ts-ignore
          columns={columns}
          rows={rows}
          loading={loading}
          density="compact"
          initialState={{pinnedColumns: {right: ["actions"]}}}

          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}

          headerFilters
        />
      </CardComponent>
      {selectedOrder && (
        <EditOrderModal
          orderId={selectedOrder.id}
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          order={selectedOrder}
          onEdit={handleEditOrder}
        />
      )}
      {selectedOrder && (
        <ConfirmDeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          orderId={selectedOrder.id}
          onDelete={handleDeleteOrder}
          market={selectedOrder.market}
        />
      )}
      {selectedOrder && (
        <ConfirmPOModal
          open={confirmPOModalOpen}
          onClose={() => setConfirmPOModalOpen(false)}
          orderId={selectedOrder.id}
          onPO={handlePOOrder}
        />
      )}
    </>
  );
};

export default MpvOrders;
