// ----- Modules ----- //
import React, { ReactNode, useCallback, useState } from "react";
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { MarketType } from "../utils/Types";
import { enqueueSnackbar } from "notistack";

// ----- Utils ----- //
interface MarketsContextInt {
  getMarkets: () => Promise<MarketType[]>;
  loading: boolean;
  error: string | null;
}

interface MarketsProviderProps {
  children: ReactNode;
}

const MarketsContext = React.createContext<MarketsContextInt>({
  getMarkets: async () => [],
  loading: true,
  error: null,
});

const MarketsProvider: React.FC<MarketsProviderProps> = ({children}) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [markets, setMarkets] = useState<MarketType[] | null>(null);

  // ----- Functions ----- //
  const fetchMarkets = useCallback(async (): Promise<MarketType[]> => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/markets`);
      const formattedData = response.data.filter((market: MarketType) => market.active);
      setMarkets(formattedData);
      return formattedData;
    } catch (error: any) {
      setError(error.message || "Failed to fetch markets");
      enqueueSnackbar(error.message || "Failed to fetch markets", {variant: "error"});
      return [];
    } finally {
      setLoading(false);
    }
  }, [axiosInstance]);

  const getMarkets = async (): Promise<MarketType[]> => {
    return markets ?? fetchMarkets();
  };

  // ----- Render ----- //
  return (
    <MarketsContext.Provider value={{getMarkets, loading, error}}>
      {children}
    </MarketsContext.Provider>
  );
};

export { MarketsContext, MarketsProvider };
