// ----- Modules ----- //
import React, { useEffect, useState } from "react";

// ----- MUI ----- //
import { Box, Card, Divider, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/ErrorOutline";

// ----- Utils ----- //
interface ErrorProps {error: boolean;}

const Error: React.FC<ErrorProps> = ({error}) => {
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    if (!error) return;

    const interval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) window.location.reload();

    return () => clearInterval(interval);
  }, [countdown, error]);

  return error ? (
    <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
      <Card sx={{width: 500, px: 3, py: 4, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Divider sx={{width: '100%', mb: 3}}/>

        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          <ErrorIcon sx={{fontSize: 60, color: 'red'}}/>

          <Typography variant="h3" sx={{color: 'red', marginBottom: 1, fontWeight: 'bold'}}>
            ERROR
          </Typography>

          <Typography variant="body1" sx={{marginBottom: 1}}>
            Failed to communicate with the server.
          </Typography>
          <Typography variant="body2" sx={{marginBottom: 3}}>
            Please try again later. If the problem persists, contact an administrator.
          </Typography>
        </Box>

        <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', p: 1}}>
          <Typography variant="body1" color="text.secondary">
            Retrying in {countdown} seconds...
          </Typography>
        </Box>

        <Divider sx={{width: '100%', mt: 2}}/>
      </Card>
    </Box>
  ) : null;
};

export default Error;
