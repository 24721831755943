// ----- Modules ----- //
import React, { useCallback } from "react";
// ----- MUI ----- //
import { Box, Skeleton } from "@mui/material";
import { BarChart } from "@mui/x-charts";

// ----- Types ----- //
type GraphProps = {
  data: number[];
  xAxis: string[];
  barColors: string[];
  height: number;
};

const BarGraph: React.FC<GraphProps> = ({data, xAxis, barColors, height}) => {
  const valueFormatter = useCallback(
    (value: number | null) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value || 0),
    []
  );

  return (
    <Box sx={{height: '100%', overflowX: 'auto', transition: 'all 0.5s ease'}} height={height}>
      {data.length > 0 ? (
        <BarChart
          series={[{data, valueFormatter}]}
          xAxis={[
            {
              scaleType: "band",
              data: xAxis,
              tickLabelStyle: {
                angle: -20,
                textAnchor: "end",
                fontSize: 10,
                fontWeight: "bold",
              },
              colorMap: {
                type: "ordinal",
                colors: barColors,
              },
            },
          ]}
          yAxis={[
            {
              tickLabelStyle: {
                angle: -60,
              },
            },
          ]}
          borderRadius={5}
          sx={{height: '100%'}}
        />
      ) : (
        <Skeleton variant="rounded" animation="wave" height="90%" sx={{mt: '25px'}}/>
      )}
    </Box>
  );
};

export default BarGraph;
