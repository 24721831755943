// ----- Modules ----- //
import React, { cloneElement, ReactElement, useCallback, useEffect, useRef, useState } from "react";
// ----- MUI ----- //
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

// ----- Types ----- //
interface CardComponentProps {
  icon?: ReactElement;
  title: string;
  subtitle?: string;
  color: string;
  children: ReactElement<{ height: number }>;
  defaultHeight?: string | number;
  header?: React.ReactNode;
  show?: boolean;
  gridSize?: { lg: number; xl: number };
  hideMaximize?: boolean;
}

const CardComponent: React.FC<CardComponentProps> = ({
                                                       icon,
                                                       title,
                                                       subtitle,
                                                       color,
                                                       children,
                                                       defaultHeight = "300px",
                                                       header,
                                                       show = true,
                                                       gridSize = {lg: 6, xl: 6},
                                                       hideMaximize = false
                                                     }) => {
  const [maximized, setMaximized] = useState(false);
  const cardRef = useRef<HTMLDivElement | null>(null);

  const toggleMaximize = useCallback(() => {
    setMaximized((prevMaximized) => !prevMaximized);
  }, []);

  useEffect(() => {
    if (maximized && cardRef.current) {
      const offset = 80;
      const topPosition =
        cardRef.current.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({top: topPosition, behavior: "smooth"});
    }
  }, [maximized]);

  return (
    <Grid
      item
      xs={12}
      lg={maximized ? 12 : gridSize.lg}
      xl={maximized ? 12 : gridSize.xl}
      minWidth={0}
      ref={cardRef}
      sx={{display: show ? "block" : "none"}}
    >
      <Paper
        sx={{
          m: {xs: "10px 0", lg: "10px 10px"},
          p: 2,
          position: "relative",
          transition: "all 0.5s ease",
          borderLeft: `5px solid ${color}`,
          "&:hover": {boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)"},
        }}
        elevation={3}
      >
        <Box sx={{
          position: 'absolute',
          display: hideMaximize ? 'none' : 'initial',
          top: 15, right: 8
        }}>
          <Tooltip title={maximized ? "Minimize" : "Maximize"}>
            <IconButton onClick={toggleMaximize} size="small">
              {maximized ? <FullscreenExitIcon/> : <FullscreenIcon/>}
            </IconButton>
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", sm: "row"},
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{display: "flex", alignItems: "center", gap: {xs: 0, sm: 1}}}>
            {icon}
            <Typography
              variant="h5"
              component="div"
              sx={{
                whiteSpace: {xs: "normal", sm: "nowrap"},
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant="subtitle2"
                component="div"
                sx={{color: "text.secondary", mt: "auto"}}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
          {header && <Box sx={{display: "flex", alignItems: "center", gap: 1, mr: {xs: 0, sm: 4}}}>{header}</Box>}
        </Box>

        <Box sx={{transition: "all 0.5s ease", width: "100%"}} height={maximized ? "700px" : defaultHeight}>
          {cloneElement(children, {height: maximized ? 700 : parseInt(defaultHeight as string, 10)})}
        </Box>
      </Paper>
    </Grid>
  );
};

export default CardComponent;
