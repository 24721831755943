// ----- Modules ----- //
import React, { ReactNode, useCallback, useContext, useState } from "react";
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { MarketPaymentsType, MarketType, PaymentType } from "../utils/Types";
import moment from "moment";
import { MarketsContext } from "./MarketsProvider";
import { enqueueSnackbar } from "notistack";

// ----- Utils ----- //
interface PaymentsContextInt {
  getPaymentsByMarkets: (from: string, to: string, force?: boolean) => Promise<MarketPaymentsType[]>;
  loading: boolean;
  error: string | null;
}

interface PaymentsProviderProps {
  children: ReactNode;
}

const PaymentsContext = React.createContext<PaymentsContextInt>({
  getPaymentsByMarkets: async () => [],
  loading: true,
  error: null,
});

const PaymentsProvider: React.FC<PaymentsProviderProps> = ({children}) => {
  const axiosInstance = useConfiguredAxios();
  const {getMarkets} = useContext(MarketsContext);

  // ----- States ----- //
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [payments, setPayments] = useState<MarketPaymentsType[] | null>(null);
  const [lastFrom, setLastFrom] = useState<string>("");
  const [lastTo, setLastTo] = useState<string>("");
  const [lastFetchTime, setLastFetchTime] = useState<moment.Moment>(moment());

  // ----- Functions ----- //
  const fetchPaymentsByMarkets = useCallback(async (from: string, to: string): Promise<MarketPaymentsType[]> => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/payments?from=${from}&to=${to}`);
      const markets = await getMarkets();

      const formattedData = markets
        .map((market: MarketType) => {
          const marketPayments = response.data.filter(
            (payment: PaymentType) => payment.market_id === market.id
          );
          return {market, payments: marketPayments};
        })
        .sort((a, b) => b.payments.length - a.payments.length);

      setPayments(formattedData);
      setLastFetchTime(moment());
      return formattedData;
    } catch (error: any) {
      setError(error.message || "Failed to fetch payments data");
      enqueueSnackbar(error.message || "Failed to fetch payments data", {variant: "error"});
      return [];
    } finally {
      setLoading(false);
    }
  }, [axiosInstance, getMarkets]);

  const getPaymentsByMarkets = useCallback(
    async (from: string, to: string, force = false): Promise<MarketPaymentsType[]> => {
      if (force || !payments || from !== lastFrom || to !== lastTo) {
        if (from === '' || to === '') {
          return fetchPaymentsByMarkets(lastFrom, lastTo);
        } else {
          setLastFrom(from);
          setLastTo(to);
          return fetchPaymentsByMarkets(from, to);
        }
      }
      return payments || [];
    },
    [payments, fetchPaymentsByMarkets, lastFrom, lastTo]
  );

  // ----- Render ----- //
  return (
    <PaymentsContext.Provider value={{getPaymentsByMarkets, loading, error}}>
      {children}
    </PaymentsContext.Provider>
  );
};

export { PaymentsContext, PaymentsProvider };
