// ----- Modules ----- //
import React from "react";
import { Grid } from "@mui/material";

// ----- Components ----- //
import InvoicesByMarketBar from "./components/InvoicesByMarketBar";

const Dashboard = () => {
  return (
    <Grid container>
      <InvoicesByMarketBar/>
    </Grid>
  );
};

export default Dashboard;
