// ----- Modules ----- //
import React from "react";

// ----- MUI ----- //
import { Grid } from "@mui/material";

// ----- Components ----- //
import SkyboxInvoices from "./components/SkyboxInvoices";
import DsSales from "./components/DsSales";

const Invoices = () => {
  return (
    <Grid container>
      <SkyboxInvoices/>
      <DsSales/>
    </Grid>
  );
};

export default Invoices;
