import React from "react";
import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "@emotion/styled";
import { DataGridPremium } from '@mui/x-data-grid-premium';
import ThemedScrollbar from "./components/Scrollbar";

export default function DefaultTheme() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: (themeParam) => ({
              body: ThemedScrollbar(prefersDarkMode),

              // This is the style for the paper elevation element
              '#content': {
                backgroundColor: themeParam.palette.mode !== "dark" ? 'rgba(255,255,255,0.5) !important'
                  : 'rgba(0,0,0,0.5) !important',
              },

              "& .MuiDialogContent-root": {
                paddingBottom: '10px !important',
              },
            })
          }
        },
      }),
    [prefersDarkMode]
  );
}

const CustomDataGrid = styled(DataGridPremium)({
  '& .MuiFormLabel-root': {
    opacity: 0.5,
    lineHeight: 3,
    marginBottom: '10px',
    fontSize: '18px',
    top: '-3px',
  },
  '& .MuiButtonBase-root': {
    padding: '3px',
    margin: '0px 5px',
  },
  '& .MuiInputBase-input': {
    color: 'white',
    fontSize: '12px',
    height: '15px',
  },
  '& .MuiSelect-select': {
    height: '10px !important',
    paddingBottom: '2px !important',
  },
  '& .MuiInputBase-root': {
    marginTop: '0px',
  },
  '& .MuiFormLabel-root ': {
    transform: 'translate(0, -13px) scale(0.75)',
  },
  '& .MuiDataGrid-footerContainer': {
    minHeight: '40px',
    maxHeight: '40px',
  },
  '& .MuiTablePagination-root': {
    overflow: 'hidden !important'
  },
  '& .MuiTablePagination-toolbar': {
    minHeight: '0 !important',
    height: '30px'
  },
  ".row-even": {backgroundColor: "rgba(0,0,0,0)"},
  ".row-odd": {backgroundColor: "rgba(125,125,125,0.05)"},
  ".group-row": {fontWeight: "bold"}
});

const ModalStyle = {
  overflow: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "7px",
  boxShadow: 24,
  maxHeight: "90%",
  px: 4,
  pb: 2,
};

export { CustomDataGrid, ModalStyle };
