// ----- Modules ----- //
import React, { ReactNode, useCallback, useState } from "react";
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { GridGetRowsParams } from "@mui/x-data-grid-premium";
import { InvoiceType } from "../utils/Types";
import { enqueueSnackbar } from "notistack";

// ----- Types ----- //
export interface SalesSearchParams extends GridGetRowsParams {
  year: string;
  month: string;
  onlyUnverified: boolean;
  onlyWithPayments: boolean;
}

interface DsSalesContextInt {
  getSales: (searchParams: SalesSearchParams) => Promise<{
    sales: InvoiceType[],
    total: number
  }>;
  loading: { sales: boolean };
  error: boolean;
}

interface DsSalesProviderProps {
  children: ReactNode;
}

const DsSalesContext = React.createContext<DsSalesContextInt>({
  getSales: async () => ({sales: [], total: 0}),
  loading: {sales: false},
  error: false,
});

const DsSalesProvider: React.FC<DsSalesProviderProps> = ({children}) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [loading, setLoading] = useState({sales: false});
  const [error, setError] = useState(false);

  // ----- Functions ----- //
  const fetchSales = useCallback(
    async (searchParams: SalesSearchParams): Promise<{
      sales: InvoiceType[],
      total: number
    }> => {
      try {
        const response = await axiosInstance.get(`/api/sales?${new URLSearchParams(searchParams as any).toString()}`);
        return response.data;
      } catch (error) {
        enqueueSnackbar("Failed to fetch sales data.", {variant: "error"});
        throw error;
      }
    },
    [axiosInstance]
  );

  const getSales = async (searchParams: SalesSearchParams): Promise<{
    sales: InvoiceType[],
    total: number
  }> => {
    setLoading(prev => ({...prev, sales: true}));
    setError(false);

    try {
      return await fetchSales(searchParams);
    } catch (error) {
      setError(true);
      return {sales: [], total: 0};
    } finally {
      setLoading(prev => ({...prev, sales: false}));
    }
  };

  // ----- Render ----- //
  return (
    <DsSalesContext.Provider value={{getSales, loading, error}}>
      {children}
    </DsSalesContext.Provider>
  );
};

export { DsSalesContext, DsSalesProvider };
