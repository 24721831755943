// ----- Modules ----- //
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import ReactDOM from 'react-dom';
import moment from "moment";
import { enqueueSnackbar } from "notistack";

export interface ExtractedInfo {
  paymentDate: string | null;
  paymentId: string | null;
  account: 'LONG' | 'DS';
  currency?: string;
}

export const askDate = (): Promise<Date | null> => {
  return new Promise((resolve) => {
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);

    const ModalComponent = () => {
      const [selectedDate, setSelectedDate] = useState<Date | null>(moment().toDate());

      const handleClose = (confirmed: boolean) => {
        if (confirmed && selectedDate) {
          enqueueSnackbar('Payment date selected', {variant: 'success'});
          resolve(selectedDate);
        } else {
          enqueueSnackbar('Payment date selection canceled', {variant: 'info'});
          resolve(null);
        }
        ReactDOM.unmountComponentAtNode(modalRoot);
        document.body.removeChild(modalRoot);
      };

      return (
        <Dialog
          open={true}
          onClose={() => handleClose(false)}
          PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              handleClose(true);
            },
          }}
        >
          <DialogTitle>Date of the payment</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The payment date is required to proceed.
            </DialogContentText>
            <TextField
              size="small"
              autoFocus
              margin="dense"
              id="date"
              label="Date"
              type="date"
              fullWidth
              value={moment(selectedDate).format('YYYY-MM-DD')}
              onChange={(event) => setSelectedDate(moment(event.target.value).toDate())}
              focused
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">Cancel</Button>
            <Button type="submit" color="primary">OK</Button>
          </DialogActions>
        </Dialog>
      );
    };

    ReactDOM.render(<ModalComponent/>, modalRoot);
  });
};
