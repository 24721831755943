// ----- Modules ----- //
import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
// ----- MUI ----- //
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Tooltip } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
// ----- Components ----- //
import TransactionCard from './TransactionCard';
// ----- Contexts ----- //
import { TransactionsContext } from "../../../contexts/TransactionsProvider";
// ----- Utils ----- //
import { parseCsv, parseXlsx } from "../../../components/paymentsImport/utils/fileProcessing";
import { useSnackbar } from "notistack";
import TransactionsDataGridModal from "./TransactionsDataGridModal";

const NotVerified: React.FC = () => {
  const {getNotVerifiedTransactions, notVerifiedLoading} = useContext(TransactionsContext);
  const {enqueueSnackbar} = useSnackbar();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [inputKey, setInputKey] = useState<number>(0);
  const [transactions, setTransactions] = useState<any[]>([]); // State to store formatted transactions
  const [modalOpen, setModalOpen] = useState<boolean>(false);  // State to control modal visibility
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string | null>(null); // State to store uploaded file name
  const fileInputRef = useRef<HTMLInputElement>(null);

  const parseFile = async (file: File): Promise<any[] | null> => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension === 'csv') {
      return await parseCsv(file, undefined, 5);
    } else if (fileExtension === 'xlsx') {
      return await parseXlsx(file, undefined, 5);
    } else {
      enqueueSnackbar('Unsupported file format', {variant: 'error'});
      return null;
    }
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      await processFile(file);
    } else {
      resetFileInput();
    }
  };

  const processFile = async (file: File) => {
    setIsProcessing(true);
    setLoading(true);
    try {
      const parsedData = await parseFile(file);
      if (!parsedData) return;

      const filteredParsedData = parsedData.filter((row) => row['Transaction Type'] === 'REGULAR CHARGES')
        .filter((row) => row['Transaction Description 4'] || row['Transaction Description 3'].length >= 7);

      const formattedTransactions = filteredParsedData.map((row) => {
        const rocId = row['ROC ID'];
        const processedRocId = processRocId(rocId);

        return {
          id: Math.random().toString(36).substring(7),
          uuid: processedRocId,
          provider: 'American Express',
          network: 'American Express',
          cc_guid: row['Transaction Description 4'] || row['Transaction Description 3'],
          currency: row['Submitted Currency'] === 'CANADIAN DOLLAR' ? 'CAD' : 'USD',
          amount: parseFloat(row['Submitted Currency Amount'].replace(/,/g, '')),
          event_type: null,
        };
      });

      formattedTransactions.sort((a, b) => {
        if (a.uuid === '0') return -1;
        if (b.uuid === '0') return 1;
        return 0;
      });

      setTransactions(formattedTransactions);  // Set the formatted transactions to state
      setModalOpen(true);  // Open the modal to show transactions
    } catch (e) {
      enqueueSnackbar('Error processing file.', {variant: 'error'});
      console.error(e);
    } finally {
      setLoading(false);
      setIsProcessing(false);
    }
  };

  const processRocId = (rocId: string) => {
    if (!rocId) return '';

    if (rocId.indexOf('-') === -1 && rocId.indexOf('/') === -1 && rocId !== '0')
      return rocId.replace(/0+$/, '');

    const dashIndex = rocId.indexOf('-');
    if (dashIndex === -1) return rocId;

    const lengthAfterDash = rocId.length - dashIndex - 1;

    const slashIndex = rocId.indexOf('/');

    if (slashIndex === -1) return rocId;

    const rightPart = rocId.substring(slashIndex + 1);
    const leftPart = rocId.substring(0, 3);

    if (lengthAfterDash === 4) {
      return `${rightPart}%/${leftPart}`;
    } else {
      return `${rightPart}/${leftPart}`;
    }
  };

  const resetFileInput = () => {
    setInputKey(prevKey => prevKey + 1);
  };

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setTransactions([]);
    setFileName(null);
    resetFileInput();
  }, []);

  const handleAcceptData = (rows: any[]) => {
    console.log('Accepted rows:', rows);
    enqueueSnackbar(`${rows.length} rows accepted for processing.`, {variant: 'success'});
    setModalOpen(false);
  };

  useEffect(() => {
    if (transactions.length > 0) setModalOpen(true);
  }, [transactions]);

  return (
    <>
      <TransactionCard
        icon={<SearchIcon/>}
        title="Not verified"
        header={
          <>
            <input
              key={inputKey}
              ref={fileInputRef}
              accept=".csv, .xlsx"
              style={{display: 'none'}}
              type="file"
              onChange={handleFileUpload}
              disabled={isProcessing}
            />

            <IconButton
              color="primary"
              aria-label="upload csv or xlsx"
              component="span"
              disabled={isProcessing}
              onClick={() => fileInputRef.current?.click()}
            >
              <UploadFileIcon fontSize="small"/>
            </IconButton>

            <Tooltip
              title={"The transactions are being auto-verified every day, for 3 days. If no match is found, the transaction will be flagged."}>
              <InfoIcon fontSize="small" cursor="pointer"/>
            </Tooltip>
          </>
        }
        color={"#ffeb3b"}
        fetchTransactions={getNotVerifiedTransactions}
        loading={notVerifiedLoading}
        noRowsLabel="No transactions to verify"
      />

      <TransactionsDataGridModal
        loading={loading}
        open={modalOpen}
        onClose={handleCloseModal}
        data={transactions}
        onAccept={handleAcceptData}
        fileName={fileName}
        status="Transactions processed"
      />
    </>
  );
};

export default NotVerified;
