// ----- Modules ----- //
import moment from "moment";

export const TicketColumns = [
  {field: 'purchase_id', headerName: 'Purchase ID', width: 150},
  {
    field: 'purchase.event_id',
    headerName: 'Event ID',
    width: 150,
    valueGetter: (_: never, row: any) => row.purchase?.event_id
  },
  {field: 'seat', headerName: 'Seat', width: 100},
  {field: 'row', headerName: 'Row', width: 100},
  {field: 'section', headerName: 'Section', width: 150},
  {
    field: 'cost',
    headerName: 'Cost',
    width: 100,
    renderCell: (params: any) => `$${parseFloat(params.value).toFixed(2)}`
  },
  {
    field: 'purchase.tags',
    headerName: 'Tags',
    width: 150,
    valueGetter: (_: never, row: any) => row.purchase?.tags
  },
  {
    field: 'purchase.notes',
    headerName: 'Notes',
    width: 300,
    valueGetter: (_: never, row: any) => row.purchase?.notes
  },
  {
    field: 'created_at', headerName: 'Created At', width: 200,
    valueFormatter: (value: any) => moment(value).format('YYYY-MM-DD HH:mm:ss')
  },
];
