// ----- Modules ----- //
import React from 'react';
// ----- MUI ----- //
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
// ----- Icons ----- //
import CloseIcon from '@mui/icons-material/Close';
// ----- Utils ----- //
import DOMPurify from 'dompurify';
import { ValueFormatter } from "../../../utils/ValueFormatter";

interface EmailModalProps {
  open: boolean;
  onClose: () => void;
  subject: string;
  sender: string;
  recipient: string;
  datetime: string;
  htmlContent: string;
  textContent: string;
  attachments: { filename: string; contentType: string; size: number; contentId?: string; content?: string }[];
}

const EmailModal: React.FC<EmailModalProps> = ({
                                                 open,
                                                 onClose,
                                                 subject,
                                                 sender,
                                                 recipient,
                                                 datetime,
                                                 htmlContent,
                                                 textContent,
                                                 attachments,
                                               }) => {
  const cidToDataUrlMap: Record<string, string> = {};

  attachments.forEach((attachment) => {
    if (attachment.contentId && attachment.content) {
      cidToDataUrlMap[attachment.contentId] = `data:${attachment.contentType};base64,${attachment.content}`;
    }
  });

  const processedHtmlContent = htmlContent.replace(/cid:([\w.@-]+)/gi, (match, cid) => {
    return cidToDataUrlMap[cid] || match;
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h5" gutterBottom>
            {subject}
          </Typography>
          <Typography variant="body2" gutterBottom color={'text.secondary'}>
            <strong>From:</strong> {sender}
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant="body2" gutterBottom color={'text.secondary'}>
              <strong>To:</strong> {recipient}
            </Typography>
            <Typography variant="body2" gutterBottom color={'text.secondary'}>
              <strong>Date:</strong> {ValueFormatter.date(datetime, true)}
            </Typography>
          </Box>
        </Box>

        <IconButton onClick={onClose} style={{position: 'absolute', right: 10, top: 10}}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <Divider/>

      <DialogContent
        sx={{
          m: 2,
          p: 0,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        {htmlContent ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(processedHtmlContent, {
                ADD_TAGS: ['img'],
                ADD_ATTR: ['src', 'alt', 'title', 'style'],
              }),
            }}
            style={{color: 'inherit', backgroundColor: 'inherit'}} // Ensuring inline styles don't conflict
          />
        ) : (
          <pre style={{color: 'inherit', backgroundColor: 'inherit'}}>
      {textContent}
    </pre>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EmailModal;
