// ----- Modules ----- //
import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import { animated, useSpring } from "react-spring";

// ----- MUI ----- //
import { Box, Chip, Skeleton } from "@mui/material";
import SellIcon from '@mui/icons-material/Sell';
import ReceivedIcon from '@mui/icons-material/AccountBalanceWallet';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

// ----- Components ----- //
import CardComponent from "../../../components/CardComponent";
import BarGraph from "../../../components/Graphs/BarGraph";
import SeeMore from "../../../components/Header/SeeMore";

// ----- Contexts ----- //
import { InvoicesContext } from "../../../contexts/InvoicesProvider";
import { PaymentsContext } from "../../../contexts/PaymentsProvider";
import { NavContext } from "../../../contexts/NavProvider";

// ----- Utils ----- //
import { MarketInvoicesType, MarketPaymentsType } from "../../../utils/Types";
import { ValueFormatter } from "../../../utils/ValueFormatter";
import PieGraph from "../../../components/Graphs/PieGraph";

const InvoicesByMarketBar: React.FC = () => {
  const {getInvoicesByMarkets} = useContext(InvoicesContext);
  const {getPaymentsByMarkets} = useContext(PaymentsContext);
  const {tabs} = useContext(NavContext);

  const [invoices, setInvoices] = useState<MarketInvoicesType[]>([]);
  const [totalMade, setTotalMade] = useState(0);
  const [payments, setPayments] = useState<MarketPaymentsType[]>([]);
  const [totalReceived, setTotalReceived] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async (date: moment.Moment) => {
    try {
      const from = date.startOf('month').format('YYYY-MM-DD');
      const to = date.endOf('month').format('YYYY-MM-DD');
      setLoading(true);
      const invoiceData = await getInvoicesByMarkets(date);
      setInvoices(invoiceData);
      setTotalMade(invoiceData.reduce((acc, row) =>
        acc + row.invoices.reduce((sum, invoice) => sum + invoice.cost + invoice.profit, 0), 0)
      );

      const paymentData = await getPaymentsByMarkets(from, to);
      setPayments(paymentData);
      setTotalReceived(paymentData.reduce((acc, payment) =>
        acc + payment.payments.reduce((sum, pmt) => sum + pmt.total, 0), 0)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(moment());
  }, [fetchData]);

  const springProps = useSpring({
    opacity: 1,
    transform: 'translateY(0px)',
    from: {opacity: 0, transform: 'translateY(-20px)'},
    config: {tension: 200, friction: 20},
  });

  const totalMadeSpring = useSpring({number: totalMade, config: {duration: 500}});
  const totalReceivedSpring = useSpring({number: totalReceived, config: {duration: 500}});

  return (
    <>
      <CardComponent icon={<SellIcon/>} title="Made this month" color="#4caf50" header={
        <>
          {loading ? (
            <>
              <Skeleton variant="rectangular" width={120} height={28} sx={{borderRadius: '16px', mb: 1}}/>
              <Skeleton variant="rectangular" width={80} height={28} sx={{borderRadius: '16px', mb: 1}}/>
              <Skeleton variant="circular" width={28} height={28} sx={{mb: 1}}/>
            </>
          ) : (
            <>
              {totalMade > 0 && (
                <HoverableChip springProps={springProps} valueSpring={totalMadeSpring} label="primary"
                               formatter={ValueFormatter.money}/>
              )}
              <HoverableChip springProps={springProps} label="success" icon={<TrendingUpIcon/>} staticLabel="N/A%"/>
              <SeeMore tab={tabs.findIndex(tab => tab.name === 'Tickets')}/>
            </>
          )}
        </>
      }>
        {loading ? (
          <Skeleton variant="rectangular" height={300}/>
        ) : (
          <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <Box sx={{flex: 3, height: '100%'}}>
              <BarGraph
                data={invoices.map(row => row.invoices.reduce((acc, invoice) => acc + invoice.cost + invoice.profit, 0))}
                xAxis={invoices.map(row => row.market.name_alt || row.market.name)}
                barColors={invoices.map(row => row.market.color)}
                height={300}
              />
            </Box>
            <Box sx={{flex: 1}}>
              <PieGraph
                data={invoices.map(row => ({
                  value: row.invoices.reduce((acc, invoice) => acc + invoice.cost + invoice.profit, 0),
                  label: row.market.name_alt || row.market.name
                }))}
                height={250}
                colors={invoices.map(row => row.market.color)}
              />
            </Box>
          </Box>
        )}
      </CardComponent>

      <CardComponent icon={<ReceivedIcon/>} title="Received this month" color="#2196f3" header={
        <>
          {loading ? (
            <>
              <Skeleton variant="rectangular" width={120} height={28} sx={{borderRadius: '16px', mb: 1}}/>
              <Skeleton variant="rectangular" width={80} height={28} sx={{borderRadius: '16px', mb: 1}}/>
              <Skeleton variant="circular" width={28} height={28} sx={{mb: 1}}/>
            </>
          ) : (
            <>
              {totalReceived > 0 && (
                <HoverableChip springProps={springProps} valueSpring={totalReceivedSpring} label="primary"
                               formatter={ValueFormatter.money}/>
              )}
              <HoverableChip springProps={springProps} label="error" icon={<TrendingDownIcon/>} staticLabel="N/A%"/>
              <SeeMore tab={tabs.findIndex(tab => tab.name === 'Payments')}/>
            </>
          )}
        </>
      }>
        {loading ? (
          <Skeleton variant="rectangular" height={300} sx={{flex: 1}}/>
        ) : (
          <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <Box sx={{flex: 3, height: '100%'}}>
              <BarGraph
                data={payments.map(payment => payment.payments.reduce((acc, pmt) => acc + pmt.total, 0))}
                xAxis={payments.map(payment => payment.market.name_alt || payment.market.name)}
                barColors={payments.map(payment => payment.market.color)}
                height={300}
              />
            </Box>
            <Box sx={{flex: 1}}>
              <PieGraph
                data={payments.map(row => ({
                  value: row.payments.reduce((acc, pmt) => acc + pmt.total, 0),
                  label: row.market.name_alt || row.market.name
                }))}
                height={250}
                colors={invoices.map(row => row.market.color)}
              />
            </Box>
          </Box>
        )}
      </CardComponent>
    </>
  );
};

const HoverableChip: React.FC<{
  springProps: any;
  valueSpring?: any;
  label: "primary" | "success" | "error";
  icon?: any;
  staticLabel?: string;
  formatter?: (value: number) => string;
}> = ({springProps, valueSpring, label, icon, staticLabel, formatter}) => {
  const [hoverProps, setHoverProps] = useSpring(() => ({
    transform: 'translateY(0px)',
    config: {tension: 300, friction: 10},
  }));

  return (
    <animated.div
      style={{...springProps, ...hoverProps}}
      onMouseEnter={() => setHoverProps({transform: 'translateY(-3px)'})}
      onMouseLeave={() => setHoverProps({transform: 'translateY(0px)'})}
    >
      <Chip
        sx={{cursor: 'default'}}
        label={valueSpring ? (
          <animated.span>
            {valueSpring.number.to((n: number) => (formatter ? formatter(n) : n))}
          </animated.span>
        ) : staticLabel}
        color={label}
        icon={icon}
      />
    </animated.div>
  );
};

export default InvoicesByMarketBar;
