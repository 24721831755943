import { ExtractedInfo } from "../utils";

export const extractTmResaleInfo = (fileName: string): ExtractedInfo => {
  let paymentDate: string | null = null;
  let paymentId: string | null = null;
  let account: 'LONG' | 'DS' = 'LONG';
  let currency: 'USD' | 'CAD' = 'USD';

  const currencyPattern = /Currency-(\w+)\.csv/;
  const currencyMatch = fileName.match(currencyPattern);

  if (currencyMatch) currency = currencyMatch[1] === 'CAD' ? 'CAD' : 'USD';

  return {paymentDate, paymentId, account, currency};
};
