// ----- Modules ----- //
import React, { ReactNode, useCallback, useState } from "react";
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { SalesStatsType, TicketType } from "../utils/Types";
import { Moment } from "moment";
import { enqueueSnackbar } from "notistack";

// ----- Utils ----- //
interface SalesContextInt {
  loading: boolean;
  getSales: (date: Moment, page: number, pageSize: number) => Promise<{ tickets: TicketType[], total: number }>;
  stats: SalesStatsType;
}

interface SalesProviderProps {
  children: ReactNode;
}

const SalesContext = React.createContext<SalesContextInt>({
  loading: true,
  getSales: async () => ({tickets: [], total: 0}),
  stats: {} as SalesStatsType,
});

const SalesProvider: React.FC<SalesProviderProps> = ({children}) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<SalesStatsType>({} as SalesStatsType);
  const [salesData, setSalesData] = useState<{
    date: Moment,
    page: number,
    pageSize: number,
    data: { tickets: TicketType[], total: number }
  } | null>(null);

  // ----- Helper Function ----- //
  const fetchSalesData = useCallback(async (date: Moment, page: number, pageSize: number): Promise<{
    tickets: TicketType[],
    total: number
  }> => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/tickets/sold?year=${date.year()}&page=${page}&pageSize=${pageSize}`);
      setStats(response.data.stats);
      const data = {
        tickets: response.data.tickets,
        total: response.data.total,
      };
      setSalesData({date, page, pageSize, data});
      return data;
    } catch (error: any) {
      enqueueSnackbar("Failed to fetch sales data.", {variant: "error"});
      return {tickets: [], total: 0};
    } finally {
      setLoading(false);
    }
  }, [axiosInstance]);

  // ----- Functions ----- //
  const getSales = useCallback(async (date: Moment, page: number, pageSize: number): Promise<{
    tickets: TicketType[],
    total: number
  }> => {
    if (
      salesData &&
      salesData.date.year() === date.year() &&
      salesData.page === page &&
      salesData.pageSize === pageSize
    ) {
      return salesData.data;
    } else {
      return fetchSalesData(date, page, pageSize);
    }
  }, [salesData, fetchSalesData]);

  // ----- Render ----- //
  return (
    <SalesContext.Provider value={{loading, getSales, stats}}>
      {children}
    </SalesContext.Provider>
  );
};

export { SalesContext, SalesProvider };
