import moment from "moment";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ReactElement } from "react";
import { Box } from "@mui/material";

export const ValueFormatter = {
  money: (value: any) => {
    if (value === undefined || isNaN(value)) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  },
  number: (value: any) => {
    if (value === undefined) return '';
    return new Intl.NumberFormat('en-US').format(value);
  },
  date: (value: any, time = false) => {
    if (value === undefined) return '';
    return moment(value).format(time ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY');
  },
  boolean: (value: any): ReactElement => {
    if (value === undefined) return <></>;
    return (
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
        {value ? <CheckIcon sx={{color: 'green'}}/> : <CloseIcon sx={{color: 'red'}}/>}
      </Box>
    );
  }
};
