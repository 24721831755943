// ----- Modules ----- //
import React, { useCallback, useMemo } from "react";
import moment from "moment";
import Papa from "papaparse";

// ----- MUI ----- //
import { GridEventListener, useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";
import { IconButton } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

// ----- Components ----- //
import CardComponent from "../../../components/CardComponent";
import PaymentImportComponent from "../../../components/paymentsImport/PaymentImportComponent";
import PaymentWarnings from "../../../components/paymentsImport/PaymentWarnings";
import ConfirmCheck from "./ConfirmCheck";

// ----- Utils ----- //
import { CustomDataGrid } from "../../../utils/Theme";
import { MarketPaymentsType } from "../../../utils/Types";

const PaymentsByMarket: React.FC<MarketPaymentsType> = ({market, payments, show, dateRange}) => {
  const apiRef = useGridApiRef();

  // Callback for row click to handle expansion of grouped rows
  const onRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      const rowNode = apiRef.current.getRowNode(params.id);
      if (rowNode && rowNode.type === 'group') {
        apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded);
      }
    },
    [apiRef]
  );

  // Initial state configuration for grid columns and grouping
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      aggregation: {
        model: {amount: 'sum'}
      },
      columns: {
        columnVisibilityModel: {order_id: false},
      },
      rowGrouping: {
        model: ["payment_id"]
      }
    }
  });

  // Value formatter for amount to display in currency format
  const formatCurrency = useCallback((value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  }, []);

  // Memoized calculation of rows from payments
  const rows = useMemo(() => payments.flatMap(payment =>
    payment.payment_lines.map(line => ({
      ...line,
      payment_date: moment(payment.payment_date).format('MM/DD/YYYY'),
      market: payment.market,
      currency: payment.currency,
    }))
  ), [payments]);

  // Function to download payment lines as CSV
  const downloadPaymentCSV = useCallback((id: string) => {
    const payment = payments.find((p) => p.payment_id === id);
    if (!payment || payment.payment_lines.length === 0) return;

    const csvData = Papa.unparse(payment.payment_lines);
    const blob = new Blob([csvData], {type: 'text/csv'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `payment_${id}_lines.csv`;
    a.click();
    URL.revokeObjectURL(url);
  }, [payments]);

  // Column definitions for the grid
  const columns = useMemo(() => [
    {
      field: 'download',
      headerName: '',
      width: 60,
      renderCell: (params: any) => {
        if (typeof params.id !== 'string' || !params.id.includes('/')) return null;

        const id = params.id.split('/')[1] || '';
        if (!id) return null;

        return (
          <IconButton size="small" color="primary" onClick={() => downloadPaymentCSV(id)}>
            <DownloadIcon/>
          </IconButton>
        );
      },
    },
    {field: 'payment_id', headerName: 'Payment ID', width: 150},
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      type: 'number',
      valueFormatter: (value: any) => formatCurrency(value)
    },
    {field: 'currency', headerName: 'Currency', width: 75},
    {field: 'payment_date', headerName: 'Payment Date', width: 150},
    {field: 'order_id', headerName: 'ID', width: 150},
    {field: 'account', headerName: 'Account', width: 150},
    {field: 'type', headerName: 'Type', width: 150},
    {field: 'description', headerName: 'Description', width: 500},
  ], [formatCurrency]);

  // Get row class name based on conditions
  const getRowClassName = useCallback((params: any) => {
    const rowNode = apiRef.current.getRowNode(params.id);
    if (rowNode && rowNode.type === 'group') {
      return 'group-row';
    }
    return params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd';
  }, [apiRef]);

  // Actions to be rendered in the component header
  const actions = useMemo(() => (
    <>
      <PaymentWarnings marketId={market.id} dateRange={dateRange}/>
      <PaymentImportComponent marketId={market.id}/>
      {market.id === 4443058 && <ConfirmCheck marketId={market.id}/>}
    </>
  ), [market, dateRange]);

  // Main component render
  return (
    <CardComponent
      title={market.name_alt || market.name}
      color={market.color}
      header={actions}
      show={show}
      gridSize={{lg: 6, xl: 4}}
    >
      <CustomDataGrid
        columns={columns as any}
        rows={rows}
        density="compact"
        hideFooter
        initialState={initialState}
        apiRef={apiRef}
        groupingColDef={{leafField: 'order_id'}}
        getRowClassName={getRowClassName}
        onRowClick={onRowClick}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
      />
    </CardComponent>
  );
};

export default PaymentsByMarket;
