// ----- Modules ----- //
import React from "react";
// ----- MUI Components ----- //
import { IconButton, Tooltip } from "@mui/material";
// ----- MUI Icons ----- //
import VisibilityIcon from '@mui/icons-material/Visibility';
// ----- Contexts ----- //
import { NavContext } from "../../contexts/NavProvider";

// ----- Types ----- //
interface SeeMoreProps {
  tab: number;
}

// ----- Components ----- //
const SeeMore: React.FC<SeeMoreProps> = ({tab}) => {
  const {handleTabChange} = React.useContext(NavContext);

  return (
    <Tooltip title={`See detailed ${tab === 1 ? 'payments' : 'tickets'}`}>
      <IconButton
        color="info"
        size="small"
        onClick={() => handleTabChange(tab)}
      >
        <VisibilityIcon/>
      </IconButton>
    </Tooltip>
  );
};

export default SeeMore;
