import { ExtractedInfo } from "./utils";
import { extractFanXchangeInfo } from "./markets/FanXchangeInfo";
import { extractTicketNetworkInfo } from "./markets/TicketNetworkInfo";
import { extractTnMercuryInfo } from "./markets/TnMercuryInfo";
import { extractGameTimeInfo } from "./markets/GameTimeInfo";
import { extractTmResaleInfo } from "./markets/TmResaleInfo";
import { extractBilletCaInfo } from "./markets/BilletsCaInfo";

const extractors: Record<number, (fileName: string) => Promise<ExtractedInfo> | ExtractedInfo> = {
  3438867: extractFanXchangeInfo,  // FanXchange
  220566: async (fileName) => await extractTicketNetworkInfo(fileName),  // Ticket Network
  4450631: async (fileName) => await extractTnMercuryInfo(fileName),  // Tn Mercury
  2988231: extractGameTimeInfo,  // GameTime
  4443058: extractTmResaleInfo,  // TM Resale
  3499490: extractBilletCaInfo  // Billet.ca
};

export const extractPaymentInfo = async (
  fileName: string,
  marketId: number,
): Promise<ExtractedInfo> => {
  const extractor = extractors[marketId];
  if (!extractor) {
    throw new Error(`No extractor found for marketId: ${marketId}`);
  }

  return extractor(fileName);
};

// Function to get the market name from the market ID
export const getMarketName = (marketId: number, markets: { id: number, name: string }[]): string => {
  const market = markets.find(m => m.id === marketId);
  return market ? market.name : 'Unknown Market';
};
