import { ExtractedInfo } from "../utils";
import moment from "moment";

export const extractGameTimeInfo = (fileName: string): ExtractedInfo => {
  let paymentDate: string | null = null;
  let paymentId: string | null = null;
  let account: 'LONG' | 'DS' = 'LONG';

  const datePattern = /gametime-payout-(\d{8})-(\d{8})(?: \((\d+)\))?\.csv/;
  const match = fileName.match(datePattern);

  if (match) {
    const [, date1, date2, number] = match;

    paymentId = number ? `${date1}-${date2}-${number}` : `${date1}-${date2}`;

    const secondDate = moment(date2, "YYYYMMDD");
    let nextThursday = secondDate.clone().day(4);

    if (nextThursday.isBefore(secondDate) || nextThursday.isSame(secondDate, 'day')) {
      nextThursday.add(1, 'week');
    }

    paymentDate = nextThursday.set({hour: 8, minute: 0, second: 0}).toISOString().slice(0, 19);
    account = number ? 'DS' : 'LONG';
  }

  return {paymentDate, paymentId, account};
};
