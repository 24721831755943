// ----- Modules ----- //
import React, { useContext } from 'react';
// ----- MUI ----- //
import FlagIcon from '@mui/icons-material/Flag';
// ----- Components ----- //
import TransactionCard from './TransactionCard';
// ----- Contexts ----- //
import { TransactionsContext } from "../../../contexts/TransactionsProvider";

const Flagged: React.FC = () => {
  const {getFlaggedTransactions, flaggedLoading} = useContext(TransactionsContext);

  return (
    <TransactionCard
      icon={<FlagIcon/>}
      title="Flagged"
      color={"#f44336"}
      fetchTransactions={getFlaggedTransactions}
      loading={flaggedLoading}
      noRowsLabel="No flagged transactions"
    />
  );
};

export default Flagged;
