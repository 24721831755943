// ----- Modules ----- //
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import moment from 'moment';
// ----- MUI ----- //
import { Box } from "@mui/material";
// ----- Components ----- //
import CardComponent from '../../../components/CardComponent';
import ActionButtons from './ActionButtons';
// ----- Types ----- //
import { TransactionType } from "../../../utils/Types";
import { CustomDataGrid } from "../../../utils/Theme";

const valueFormatter = (value: any) => {
  if (value === undefined) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value);
};

const columns = [
  {
    field: 'created_at',
    headerName: 'Date',
    width: 150,
    valueFormatter: (value: any) => moment(value).format('MM/DD/YYYY')
  },
  {field: 'amount', headerName: 'Amount', width: 150, valueFormatter},
  {field: 'provider', headerName: 'Provider', width: 150},
  {field: 'merchant', headerName: 'Merchant', width: 150},
  {field: 'email', headerName: 'Email', width: 200},
  {field: 'cc_guid', headerName: 'CC GUID', width: 300},
  {field: 'currency', headerName: 'Currency', width: 100},
  {
    field: 'actions', headerName: '', width: 100,
    renderCell: (params: any) => <ActionButtons transaction={params.row}/>,
    sortable: false,
    disableColumnMenu: true,
    disableColumnSelector: true,
    disableReorder: true,
  }
];

const getRowClassName = (params: any) => (
  params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
);

interface TransactionCardProps {
  icon: ReactElement;
  title: string;
  header?: ReactElement;
  color: string;
  fetchTransactions: (page: number, pageSize: number) => Promise<{ transactions: TransactionType[], total: number }>;
  loading: boolean;
  noRowsLabel: string;
}

const TransactionCard: React.FC<TransactionCardProps> = ({
                                                           icon,
                                                           title,
                                                           header,
                                                           color,
                                                           fetchTransactions,
                                                           loading,
                                                           noRowsLabel
                                                         }) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const fetchAndSetTransactions = useMemo(
    () => debounce(async (page: number, pageSize: number) => {
      const data = await fetchTransactions(page, pageSize);
      setTransactions(data.transactions);
      setRowCount(data.total);
    }, 300),
    [fetchTransactions]
  );

  useEffect(() => {
    fetchAndSetTransactions(paginationModel.page, paginationModel.pageSize);

    return () => {
      fetchAndSetTransactions.cancel();
    };
  }, [paginationModel.page, paginationModel.pageSize, fetchAndSetTransactions]);

  const handlePaginationModelChange = (params: any) => {
    setPaginationModel(params);
  };

  return (
    <CardComponent
      icon={icon}
      title={title}
      header={header}
      color={color}
      defaultHeight="calc(100vh - 200px)"
    >
      <Box sx={{height: '100%'}}>
        <CustomDataGrid
          columns={columns}
          rows={transactions}
          density="compact"
          getRowClassName={getRowClassName}
          initialState={{pinnedColumns: {right: ['actions']}}}
          loading={loading}
          pagination
          onPaginationModelChange={handlePaginationModelChange}
          paginationMode="server"
          rowCount={rowCount}
          localeText={{noRowsLabel}}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </Box>
    </CardComponent>
  );
};

export default TransactionCard;
