import { ExtractedInfo } from "../utils";

export const extractFanXchangeInfo = (fileName: string): ExtractedInfo => {
  let paymentDate: string | null = null;
  let paymentId: string | null = null;
  let account: 'LONG' = 'LONG';

  const datePattern = /(\d+)-\w+-\w+-(\w+)-(\d+)-(\d{4})/;
  const match = fileName.match(datePattern);
  if (match) {
    const [, id, monthName, day, year] = match;
    paymentId = id;
    const monthIndex = new Date(Date.parse(`${monthName} 1, 2024`)).getMonth();
    if (monthIndex !== -1) {
      const date = new Date(Number(year), monthIndex, Number(day), 8, 0, 0);
      paymentDate = date.toISOString().slice(0, 19);
    }
  }

  return {paymentDate, paymentId, account};
};
