// ----- Modules ----- //
import React, { useMemo, useState } from "react";
// ----- MUI ----- //
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
// ----- Icons ----- //
import EditIcon from '@mui/icons-material/Edit';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// ----- Utils ----- //
import { InvoiceType, PaymentLineType } from "../../../utils/Types";
import { ModalStyle } from "../../../utils/Theme";
import { ValueFormatter } from "../../../utils/ValueFormatter";
import { useConfiguredAxios } from "../../../utils/AxiosInstance";
import { useSnackbar } from "notistack";

interface EditModalProps {
  invoice: InvoiceType;
  lines: PaymentLineType[];
}

const EditModal: React.FC<EditModalProps> = ({invoice, lines}) => {
  const axiosInstance = useConfiguredAxios();
  const {enqueueSnackbar} = useSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [editableLines, setEditableLines] = useState<PaymentLineType[]>(lines);
  const [editableInvoiceAmount, setEditableInvoiceAmount] = useState<number>(Math.round((invoice.cost + invoice.profit) * 100) / 100);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAmountChange = (id: number, newAmount: number) => {
    setEditableLines(prevLines =>
      prevLines.map(line => line.id === id ? {...line, amount: newAmount} : line)
    );
  };

  const handleInvoiceAmountChange = (newAmount: string) => {
    setEditableInvoiceAmount(parseFloat(newAmount) || 0);
  };

  const handleSave = async () => {
    await axiosInstance.put(`/api/invoices/${invoice.ref}`, {
      amount: Math.round(editableInvoiceAmount * 100) / 100,
    }).then(() => {
      handleClose();
    });

    await Promise.all(editableLines.map(line =>
      axiosInstance.put(`/api/payments/${line.id}`, {
        amount: Math.round(line.amount * 100) / 100
      })
    ));

    enqueueSnackbar('Invoice and payments updated successfully', {variant: 'success'});
  };

  const totalPaymentLines = useMemo(() =>
    editableLines.reduce((total, line) => total + line.amount, 0), [editableLines]);

  const difference = useMemo(() => totalPaymentLines - editableInvoiceAmount, [totalPaymentLines, editableInvoiceAmount]);

  const borderColor = useMemo(() => difference === 0 ? '#4caf50' : '#f50057', [difference]);

  return (
    <>
      <Tooltip title="Edit the invoice and payments" disableInteractive>
        <span>
          <IconButton
            color="secondary"
            onClick={handleOpen}
            disabled={!invoice.verified || lines.length === 0}
          >
            <EditIcon/>
          </IconButton>
        </span>
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{...ModalStyle, borderLeft: `7px solid ${borderColor}`}}>
          <Box sx={{display: "flex", alignItems: "center", my: 2}}>
            <AttachMoneyIcon sx={{fontSize: '35px'}}/>
            <Typography variant="h4">Edit amounts</Typography>
          </Box>

          <Divider sx={{mb: 1}}/>

          <Box sx={{display: "flex", flexDirection: "column", gap: 2}}>
            <Box>
              <Typography variant="h6">Invoice</Typography>
              <TextField
                label="Amount"
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={editableInvoiceAmount}
                onChange={(e) => handleInvoiceAmountChange(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">{invoice.currency}</InputAdornment>
                }}
              />
            </Box>

            <Box>
              <Typography variant="h6">Payments</Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {editableLines.map((line) => (
                      <TableRow key={line.id}>
                        <TableCell>{ValueFormatter.date(line.created_at)}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            value={line.amount}
                            onChange={(e) => handleAmountChange(line.id, parseFloat(e.target.value))}
                            fullWidth

                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              endAdornment: <InputAdornment position="end">{invoice.currency}</InputAdornment>
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <Box sx={{display: "flex", justifyContent: "space-between", gap: 1, pt: 2}}>
            <Box>
              <Typography
                sx={{pl: 0.5}}
                variant="body1"
                color={difference === 0 ? "primary" : "error"}
              >
                Total: {ValueFormatter.money(totalPaymentLines)} ({ValueFormatter.money(difference)})
              </Typography>
            </Box>


            <Box>
              <Button variant="text" onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button variant="contained" color="primary"
                      disabled={difference !== 0 || totalPaymentLines === 0}
                      onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditModal;
