// ----- Modules ----- //
import React, { useCallback, useEffect, useState } from "react";

// ----- MUI ----- //
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// ----- Icons ----- //
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

// ----- Utils ----- //
import { ModalStyle } from "../../../utils/Theme";
import { useConfiguredAxios } from "../../../utils/AxiosInstance";
import { ValueFormatter } from "../../../utils/ValueFormatter";
import { enqueueSnackbar } from "notistack";

// ----- Types ----- //
interface Payment {
  payment_id: number;
  payment_date: string;
  total: number;
  id: number;
}

const ConfirmCheck: React.FC<{ marketId: number }> = ({marketId}) => {
  const axiosInstance = useConfiguredAxios();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<{ [key: number]: boolean }>({});

  const fetchUnconfirmedPayments = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/payments/unconfirmed?marketId=${marketId}`);
      setPayments(response.data);
    } catch (error) {
      enqueueSnackbar('Failed to fetch unconfirmed payments.', {variant: 'error'});
    }
  }, [axiosInstance, marketId]);

  useEffect(() => {
    fetchUnconfirmedPayments();
  }, []);

  const confirmPayment = async (id: number) => {
    try {
      await axiosInstance.post(`/api/payments/confirm/${id}`);
      enqueueSnackbar('Payment confirmed successfully.', {variant: 'success'});
      setPayments((prevPayments) => prevPayments.filter((payment) => payment.id !== id));
    } catch (error) {
      enqueueSnackbar('Failed to confirm payment.', {variant: 'error'});
      setErrors((prevErrors) => ({...prevErrors, [id]: true}));
    }
  };

  return (
    <>
      <IconButton size="small" color="secondary" onClick={() => setOpen(true)}>
        <Badge color="error" variant="dot" invisible={payments.length === 0}>
          <CreditScoreIcon/>
        </Badge>
      </IconButton>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={ModalStyle}>
          <Box sx={{display: "flex", alignItems: "center", marginY: 2}}>
            <AccountBalanceIcon sx={{fontSize: '35px'}}/>
            <Typography variant="h4">Verify checks</Typography>
          </Box>

          <Divider sx={{mb: 1}}/>

          <TableContainer sx={{height: 400, overflow: 'auto', border: 1, borderColor: 'divider', borderRadius: 1}}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Payment ID</TableCell>
                  <TableCell>Payment Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.length > 0 ? (
                  payments.map((payment) => (
                    <TableRow key={payment.payment_id}>
                      <TableCell>{payment.payment_id}</TableCell>
                      <TableCell>{ValueFormatter.date(payment.payment_date, true)}</TableCell>
                      <TableCell>{ValueFormatter.money(payment.total)}</TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => confirmPayment(payment.id)}
                          color="success"
                          title="Confirm payment"
                        >
                          <CheckIcon/>
                        </IconButton>
                        {errors[payment.id] && (
                          <IconButton
                            size="small"
                            color="error"
                            title="Error confirming payment"
                          >
                            <CloseIcon/>
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">No unconfirmed payments found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}>
            <Button variant="text" onClick={() => setOpen(false)}>Close</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmCheck;
