import { Grid } from "@mui/material";
import Inventory from "./components/Inventory";
import Sales from "./components/Sales";

const Tickets = () => {

  return (
    <Grid container>
      <Sales/>
      <Inventory/>
    </Grid>
  );
};

export default Tickets;
