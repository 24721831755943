// ----- Modules ----- //
import React, { ReactNode, useCallback, useState } from "react";
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { TicketType } from "../utils/Types";
import { Moment } from "moment";
import { enqueueSnackbar } from "notistack";

// ----- Utils ----- //
interface TicketsContextInt {
  getTickets: (date: Moment, page: number, pageSize: number) => Promise<{ tickets: TicketType[], total: number }>;
  stats: Stats;
  loading: boolean;
  error: string | null;
  lastFetched: Date;
  getTicketStats: () => Promise<Stats>;
}

export interface Stats {
  inventory: number | null;
  sold: number | null;
  value: number | null;
}

interface TicketsProviderProps {
  children: ReactNode;
}

const TicketsContext = React.createContext<TicketsContextInt>({
  getTickets: async () => ({tickets: [], total: 0}),
  stats: {inventory: null, sold: null, value: null},
  loading: false,
  error: null,
  lastFetched: new Date(),
  getTicketStats: async () => ({inventory: null, sold: null, value: null}),
});

const TicketsProvider: React.FC<TicketsProviderProps> = ({children}) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [lastFetched, setLastFetched] = useState(new Date());
  const [ticketsData, setTicketsData] = useState<{
    date: Moment,
    page: number,
    pageSize: number,
    data: { tickets: TicketType[], total: number }
  } | null>(null);

  const [stats, setStats] = useState<Stats>({inventory: null, sold: null, value: null});

  // ----- Helper Function ----- //
  const fetchTickets = useCallback(async (date: Moment, page: number, pageSize: number): Promise<{
    tickets: TicketType[],
    total: number
  }> => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(`/api/tickets/inventory?year=${date.year()}&page=${page}&pageSize=${pageSize}`);
      const data = {
        tickets: response.data.tickets,
        total: response.data.total,
      };
      setTicketsData({date, page, pageSize, data});
      setLastFetched(new Date());
      return data;
    } catch (error: any) {
      const errorMessage = error.response?.data || 'An unexpected error occurred';
      setError(errorMessage);
      enqueueSnackbar(errorMessage, {variant: 'error'});
      return {tickets: [], total: 0};
    } finally {
      setLoading(false);
    }
  }, [axiosInstance]);

  const fetchStats = useCallback(async (): Promise<Stats> => {
    setError(null);
    try {
      const response = await axiosInstance.get('/api/tickets/stats');
      setStats(response.data);
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data || 'An unexpected error occurred';
      setError(errorMessage);
      enqueueSnackbar(errorMessage, {variant: 'error'});
      return {inventory: null, sold: null, value: null};
    }
  }, [axiosInstance]);

  // ----- Functions ----- //
  const getTickets = useCallback(async (date: Moment, page: number, pageSize: number): Promise<{
    tickets: TicketType[],
    total: number
  }> => {
    if (
      ticketsData &&
      ticketsData.date.year() === date.year() &&
      ticketsData.page === page &&
      ticketsData.pageSize === pageSize
    ) {
      return ticketsData.data;
    } else {
      return fetchTickets(date, page, pageSize);
    }
  }, [ticketsData, fetchTickets]);

  const getTicketStats = useCallback(async (): Promise<Stats> => {
    return stats.inventory ? stats : fetchStats();
  }, [stats, fetchStats]);

  // ----- Render ----- //
  return (
    <TicketsContext.Provider value={{getTickets, loading, error, lastFetched, stats, getTicketStats}}>
      {children}
    </TicketsContext.Provider>
  );
};

export { TicketsContext, TicketsProvider };
