// ----- Modules ----- //
import React, { ReactNode, useCallback, useState } from "react";
import { useConfiguredAxios } from "../utils/AxiosInstance";
import { TransactionType } from "../utils/Types";
import { enqueueSnackbar } from "notistack";

// ----- Utils ----- //
interface TransactionsContextInt {
  getNotVerifiedTransactions: (page: number, pageSize: number) => Promise<{
    transactions: TransactionType[],
    total: number
  }>;
  getFlaggedTransactions: (page: number, pageSize: number) => Promise<{
    transactions: TransactionType[],
    total: number
  }>;
  notVerifiedLoading: boolean;
  flaggedLoading: boolean;
  error: string | null;
}

interface TransactionsProviderProps {
  children: ReactNode;
}

const TransactionsContext = React.createContext<TransactionsContextInt>({
  getNotVerifiedTransactions: async () => ({transactions: [], total: 0}),
  getFlaggedTransactions: async () => ({transactions: [], total: 0}),
  notVerifiedLoading: false,
  flaggedLoading: false,
  error: null,
});

const TransactionsProvider: React.FC<TransactionsProviderProps> = ({children}) => {
  const axiosInstance = useConfiguredAxios();

  // ----- States ----- //
  const [notVerifiedLoading, setNotVerifiedLoading] = useState(false);
  const [flaggedLoading, setFlaggedLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [notVerifiedData, setNotVerifiedData] = useState<{
    page: number,
    pageSize: number,
    data: { transactions: TransactionType[], total: number }
  } | null>(null);
  const [flaggedData, setFlaggedData] = useState<{
    page: number,
    pageSize: number,
    data: { transactions: TransactionType[], total: number }
  } | null>(null);

  // ----- Helper Function ----- //
  const fetchTransactions = useCallback(async (
    url: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setData: React.Dispatch<React.SetStateAction<any>>,
    page: number,
    pageSize: number
  ): Promise<{
    transactions: TransactionType[],
    total: number
  }> => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(url);
      const data = {
        transactions: response.data.transactions,
        total: response.data.total,
      };
      setData({page, pageSize, data});
      return data;
    } catch (error: any) {
      const errorMessage = error.response?.data || 'An unexpected error occurred';
      setError(errorMessage);
      enqueueSnackbar(errorMessage, {variant: 'error'});
      return {
        transactions: [],
        total: 0,
      };
    } finally {
      setLoading(false);
    }
  }, [axiosInstance]);

  // ----- Functions ----- //
  const getNotVerifiedTransactions = useCallback(async (page: number, pageSize: number) => {
    if (
      notVerifiedData &&
      notVerifiedData.page === page &&
      notVerifiedData.pageSize === pageSize
    ) {
      return notVerifiedData.data;
    } else {
      return fetchTransactions(
        `/api/transactions/notverified?page=${page}&pageSize=${pageSize}`,
        setNotVerifiedLoading,
        setNotVerifiedData,
        page,
        pageSize
      );
    }
  }, []);

  const getFlaggedTransactions = useCallback(async (page: number, pageSize: number) => {
    if (
      flaggedData &&
      flaggedData.page === page &&
      flaggedData.pageSize === pageSize
    ) {
      return flaggedData.data;
    } else {
      return fetchTransactions(
        `/api/transactions/flagged?page=${page}&pageSize=${pageSize}`,
        setFlaggedLoading,
        setFlaggedData,
        page,
        pageSize
      );
    }
  }, []);

  // ----- Render ----- //
  return (
    <TransactionsContext.Provider
      value={{
        getNotVerifiedTransactions,
        getFlaggedTransactions,
        notVerifiedLoading,
        flaggedLoading,
        error,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};

export { TransactionsContext, TransactionsProvider };
