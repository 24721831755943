// ----- Modules ----- //
import React from "react";
// ----- MUI ----- //
import { Box } from "@mui/material";
import { PieChart } from "@mui/x-charts";

// ----- Types ----- //
type GraphProps = {
  data: { value: number; label: string }[];
  height: number;
  colors: string[];
};

const PieGraph: React.FC<GraphProps> = ({data, height, colors}) => {
  const totalValue = data.reduce((sum, item) => sum + item.value, 0);

  // Sort the data by value in descending order
  const sortedData = data
    .map((item, index) => ({
      id: index, // Assigning an ID for each slice
      value: item.value, // Keep only value and color, no label
      color: colors[index % colors.length],
    }))
    .sort((a, b) => b.value - a.value); // Sort from largest to smallest

  return (
    <Box
      sx={{
        height: '100%',
        transition: 'all 0.5s ease',
      }}
      height={height}
    >
      {sortedData.length > 0 && (
        <PieChart
          series={[
            {
              data: sortedData,
              arcLabel: (item) => {
                const percentage = ((item.value / totalValue) * 100).toFixed(1);
                return `${percentage}%`; // Show only the percentage
              },
              arcLabelMinAngle: 30,
              highlightScope: {faded: 'global', highlighted: 'item'},
              faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
            },
          ]}
          height={height}
        />
      )}
    </Box>
  );
};

export default PieGraph;
