// ----- NoDataMessage.tsx ----- //
import React from 'react';
import { Box, CardContent, Paper, Typography } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

type NoDataMessageProps = {
  message?: string;
};

const NoDataMessage: React.FC<NoDataMessageProps> = ({message = "No data available"}) => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
      <Paper elevation={3}
             sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'center',
               p: 4,
               m: 4
             }}>
        <CardContent sx={{textAlign: 'center'}}>
          <SentimentDissatisfiedIcon sx={{fontSize: 60, mb: 2, color: 'text.secondary'}}/>
          <Typography variant="h6" color="text.secondary">{message}</Typography>
        </CardContent>
      </Paper>
    </Box>
  );
};

export default NoDataMessage;
