// ----- Modules ----- //
import React from "react";
import { animated, useSpring } from "react-spring";
// ----- MUI ----- //
import { Box, Typography } from "@mui/material";
// ----- Contexts ----- //
import { NavContext } from "../../contexts/NavProvider";

// ----- Types ----- //
interface TabProps {
  tabNb: number;
  name: string;
  icon: React.ReactNode | null;
}

interface NavigationProps {
  direction?: 'row' | 'column';
  icon?: boolean;
}

// ----- Components ----- //
const Navigation: React.FC<NavigationProps> = ({direction = 'row', icon = false}) => {
  const {tabs} = React.useContext(NavContext);

  return (
    <Box sx={{display: 'flex', flexDirection: direction, gap: 3}}>
      {tabs.map((tab, index) => (
        <Tab key={index} tabNb={index} name={tab.name} icon={icon ? tab.icon : null}/>
      ))}
    </Box>
  );
};

const Tab: React.FC<TabProps> = ({tabNb, name, icon}) => {
  const {tab, handleTabChange} = React.useContext(NavContext);

  const [hoverProps, setHoverProps] = useSpring(() => ({
    transform: 'translateY(0px)',
    config: {tension: 300, friction: 10},
  }));

  const handleMouseEnter = () => setHoverProps({transform: 'translateY(-2px)'});
  const handleMouseLeave = () => setHoverProps({transform: 'translateY(0px)'});

  return (
    <animated.div
      style={hoverProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Typography
        sx={{
          cursor: 'pointer',
          position: 'relative',
          transition: 'color 0.5s ease',
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          px: 0.5,
          '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: -2,
            height: 2,
            width: '100%',
            backgroundColor: 'primary.main',
            transform: tab === tabNb ? 'scaleX(1)' : 'scaleX(0)',
            transition: 'transform 0.5s ease',
          },
        }}
        variant="h5"
        onClick={() => handleTabChange(tabNb)}
        color={tab === tabNb ? 'primary' : 'text.secondary'}
      >
        {icon}
        {name}
      </Typography>
    </animated.div>
  );
};

export default Navigation;
