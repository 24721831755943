// ----- Modules ----- //
import React, { useContext } from 'react';
// ----- MUI ----- //
import ReceiptIcon from '@mui/icons-material/Receipt';
// ----- Contexts ----- //
import { SalesContext } from "../../../contexts/SalesProvider";
// ----- Components ----- //
import TicketTable from "./TicketTable";
import { TicketColumns } from "./Columns";

const SalesWrapper: React.FC = () => {
  const {getSales, loading} = useContext(SalesContext);

  return (
    <TicketTable
      icon={<ReceiptIcon/>}
      title="Sales"
      borderColor="#9c27b0"
      dataFunction={getSales}
      columns={[
        {
          field: 'invoice_id',
          headerName: 'Invoice ID',
          width: 100,
        },
        ...TicketColumns
      ]}
      loading={loading}
    />
  );
};

export default SalesWrapper;
