// ----- Module ----- //
import React from "react";

// ----- Utils ----- //
import { NavContext } from "../contexts/NavProvider";
import { Box } from "@mui/material";

/**
 * Main Page component.
 */
const Main = (props: { page: number }) => {
  const {page} = props;

  const {tab, tabs, handleTabChange} = React.useContext(NavContext);

  React.useEffect(() => {
    handleTabChange(page);
  }, [page, handleTabChange]);

  return (
    <Box sx={{px: {xs: 0.5, md: 1, lg: 2}, py: 2}}>
      {tabs[tab].main}
    </Box>
  );
};

export default Main;
