// ----- Modules ----- //
import React, { useCallback, useContext, useEffect, useState } from "react";
import moment, { Moment } from "moment";

// ----- MUI ----- //
import { Backdrop, Box, Chip, CircularProgress, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { useTheme } from "@mui/material/styles";

// ----- Components ----- //
import PaymentsByMarket from "./components/PaymentsByMarket";
import NoDataMessage from "../../components/cards/NoDataMessage";

// ----- Utils ----- //
import { MarketType, PaymentType } from "../../utils/Types";
import { PaymentsContext } from "../../contexts/PaymentsProvider";

// ----- Shortcut Items ----- //
const shortcutsItems = [
  {
    label: 'This Month',
    getValue: () => {
      const today = moment();
      return [today.clone().startOf('month'), today.clone().endOf('day')];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = moment();
      const startOfLastMonth = today.clone().subtract(1, 'month').startOf('month');
      const endOfLastMonth = today.clone().subtract(1, 'month').endOf('month').isAfter(today)
        ? today.clone().endOf('day')
        : today.clone().subtract(1, 'month').endOf('month');
      return [startOfLastMonth, endOfLastMonth];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(7, 'days').startOf('day'), today.clone().endOf('day')];
    },
  },
  {
    label: 'Last 30 Days',
    getValue: () => {
      const today = moment();
      return [today.clone().subtract(30, 'days').startOf('day'), today.clone().endOf('day')];
    },
  },
  {
    label: 'This Week',
    getValue: () => {
      const today = moment();
      return [today.clone().startOf('week'), today.clone().endOf('day')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = moment();
      const startOfLastWeek = today.clone().subtract(1, 'week').startOf('week');
      const endOfLastWeek = today.clone().subtract(1, 'week').endOf('week').isAfter(today)
        ? today.clone().endOf('day')
        : today.clone().subtract(1, 'week').endOf('week');
      return [startOfLastWeek, endOfLastWeek];
    },
  },
];

const Payments = () => {
  const {getPaymentsByMarkets, loading, error} = useContext(PaymentsContext);
  const theme = useTheme();

  // ----- States ----- //
  const [marketsData, setMarketsData] = useState<{
    market: MarketType,
    payments: PaymentType[],
    show: boolean
  }[]>([]);
  const [fetching, setFetching] = useState(true);
  const [dateRange, setDateRange] = useState<DateRange<Moment>>([
    moment().startOf('month'),
    moment(),
  ]);

  // ----- Fetch Data ----- //
  const fetchData = useCallback(
    async (from: string, to: string) => {
      setFetching(true);
      try {
        const data = await getPaymentsByMarkets(from, to);
        const updatedData = data.map((market: any) => ({
          ...market,
          show: market.payments.length > 0,
        }));
        setMarketsData(updatedData);
      } catch (error) {
        console.error("Error fetching payments:", error);
      } finally {
        setFetching(false);
      }
    },
    [getPaymentsByMarkets]
  );

  useEffect(() => {
    const timer = setTimeout(
      () =>
        fetchData(
          dateRange[0]?.format("YYYY-MM-DD") || "",
          dateRange[1]?.format("YYYY-MM-DD") || ""
        ),
      500
    );
    return () => clearTimeout(timer);
  }, [fetchData, dateRange]);

  // ----- Handlers ----- //
  const toggleMarket = useCallback((id: number) => {
    setMarketsData((prevMarkets) =>
      prevMarkets.map((data) =>
        data.market.id === id ? {...data, show: !data.show} : data
      )
    );
  }, []);

  return (
    <>
      <Backdrop
        sx={{color: "#fff", zIndex: theme.zIndex.drawer + 1}}
        open={loading || fetching}
      >
        <CircularProgress color="primary"/>
      </Backdrop>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.75,
          flexWrap: "wrap",
          ml: 1.5,
          mb: 2,
          justifyContent: {xs: "center", sm: "flex-start"},
        }}
      >
        <DateRangePicker
          localeText={{start: "From", end: "To"}}
          slotProps={{
            textField: {size: "small", sx: {width: 150}},
            shortcuts: {
              items: shortcutsItems as any,
            },
          }}
          value={dateRange}
          onChange={setDateRange}
          calendars={1}

          maxDate={moment()}
          minDate={moment('2024-01-01')}
        />

        <Divider orientation="vertical" flexItem sx={{mx: 1.5}}/>

        {loading || fetching ? (
          Array.from(new Array(12)).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={Math.floor(Math.random() * 50) + 75}
              height={32}
              sx={{borderRadius: "16px"}}
            />
          ))
        ) : (
          marketsData.map((data) => (
            <Chip
              key={data.market.id}
              label={data.market.name_alt || data.market.name}
              onClick={() => toggleMarket(data.market.id)}
              sx={{
                backgroundColor: data.market.color,
                filter: data.show ? "opacity(100%)" : "opacity(50%)",
                color: theme.palette.getContrastText(data.market.color),
              }}
            />
          ))
        )}
      </Box>

      <Grid container>
        {loading || fetching ? (
          Array.from(new Array(9)).map((_, index) => (
            <Grid item xs={12} md={6} lg={4} key={index} p={1}>
              <Skeleton variant="rounded" width="100%" height={360}/>
            </Grid>
          ))
        ) : error ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography color="error">Error: {error}</Typography>
          </Box>
        ) : marketsData.every((data) => !data.show) ? (
          <NoDataMessage message="No data available for the selected month."/>
        ) : (
          marketsData.map((data) => (
            <MemoizedPaymentsByMarket
              key={data.market.id}
              market={data.market}
              payments={data.payments}
              show={data.show}
              dateRange={dateRange}
            />
          ))
        )}
      </Grid>
    </>
  );
};

const MemoizedPaymentsByMarket = React.memo(PaymentsByMarket);

export default Payments;
