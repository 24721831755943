// ----- Modules ----- //
import React, { useContext } from 'react';
// ----- MUI ----- //
import InventoryIcon from '@mui/icons-material/Inventory';
// ----- Contexts ----- //
import { TicketsContext } from "../../../contexts/TicketsProvider";
// ----- Components ----- //
import TicketTable from "./TicketTable";
import { TicketColumns } from "./Columns";

const InventoryWrapper: React.FC = () => {
  const {getTickets, loading} = useContext(TicketsContext);

  return (
    <TicketTable
      icon={<InventoryIcon/>}
      title="Inventory"
      borderColor="#ff9800"
      dataFunction={getTickets}
      columns={TicketColumns}
      loading={loading}
    />
  );
};

export default InventoryWrapper;
