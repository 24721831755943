import moment from "moment";
import { enqueueSnackbar } from "notistack";

export interface StandardObject {
  id: string | null;
  order_id: string | null;
  payment_id: string | null;
  payment_date: string | null;
  market_id: number;
  amount: number | null;
  account: string | null;
  type: string | null;
  description: string | null;
  updated_at: string | null;
  created_at: string | null;
}

export const headerMappings: { [marketId: number]: { [key: string]: keyof StandardObject } } = {
  3438867: { // FanXchange
    'Order ID': 'order_id',
    'Transaction Date': 'created_at',
    'Net Pay': 'amount',
    'Transaction Type': 'type',
  },
  220566: { // Ticket Network
    'ORDER ID': 'order_id',
    'TOTAL': 'amount',
    'ORDER DATE': 'created_at',
  },
  3499490: {}, // Billet.ca
  2988231: { // GameTime
    '#': 'order_id',
    'Purchased At': 'created_at',
    'Payout': 'amount',
    'Reason': 'description',
  },
  4443058: { // TM Resale
    'Web Order #': 'order_id',
    'PO Date': 'created_at',
    'Amount': 'amount',
    'Description': 'description',
    'Document Type': 'type',
  },
  4450631: { // Tn Mercury
    'MERCURY_TRANSACTION_ID': 'order_id',
  },
};

export const customMarketTransformations: {
  [marketId: number]: (row: any, account: 'LONG' | 'DS', paymentId: string, paymentDate?: string) => StandardObject
} = {
  4450631: (row, account, paymentId, paymentDate) => {
    const amount = parseFloat(row['WHOLESALE_AMT']) - parseFloat(row['FEE_AMT']);
    return {
      id: null,
      order_id: row['MERCURY_TRANSACTION_ID'] || null,
      payment_id: paymentId || null,
      payment_date: paymentDate || null,
      market_id: 4450631,
      amount: isNaN(amount) ? null : amount,
      account: account,
      type: null,
      description: null,
      updated_at: null,
      created_at: paymentDate || null,
    };
  },
  4443058: (row, account) => {
    const amount = parseFloat(row['Amount']);

    const paymentDateFormats = ['MM/DD/YYYY', 'DD/MM/YYYY'];
    const poDateFormats = ['D MMM YYYY', 'DD MMM YYYY'];

    const payment_date = parseDate(row['Paid Date'], paymentDateFormats);
    const created_at = parseDate(row['PO Date'], poDateFormats);

    const currency = row['Currency'];

    return {
      id: null,
      order_id: row['Web Order #'] || null,
      payment_id: row['Check No'] || null,
      payment_date,
      market_id: 4443058,
      amount: isNaN(amount) ? null : amount,
      account: account,
      type: row['Document Type'] || null,
      description: row['Description'] || null,
      updated_at: null,
      created_at,
      currency,
    } as StandardObject;
  },
  3499490: (row, account) => {
    const amount = parseFloat(row['paymentAmount']);
    const payment_date = parseDate(row['paymentDate'], ['YYYY-MM-DD']);

    return {
      id: null,
      order_id: row['orderNumber'] || null,
      payment_id: row['orderNumber'] || null,
      payment_date: payment_date,
      market_id: 3499490,
      amount: isNaN(amount) ? null : amount,
      account: account,
      type: null,
      description: null,
      updated_at: null,
      created_at: payment_date,
    };
  }
};

const parseDate = (dateString: string, formats: string[]): string | null => {
  for (const format of formats) {
    const date = moment(dateString, format, true);
    if (date.isValid()) {
      return date.toISOString();
    }
  }

  enqueueSnackbar(`Error: Could not parse date "${dateString}". Expected formats: ${formats.join(', ')}`, {
    variant: 'error',
    autoHideDuration: null,
  });

  console.error('Error: Could not parse date', dateString);
  return null;
};
