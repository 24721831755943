// ----- Modules ----- //
import React from "react";
import moment, { Moment } from "moment";
// ----- MUI ----- //
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface DateSelectProps {
  date: Moment;
  setDate: (date: Moment) => void;
  showMonthSelect?: boolean;
}

const DateSelect: React.FC<DateSelectProps> = ({date, setDate, showMonthSelect = false}) => {
  const handleYearChange = (event: SelectChangeEvent<string>) => {
    const selectedYear = event.target.value;
    setDate(moment(date).year(parseInt(selectedYear, 10)));
  };

  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    const selectedMonth = event.target.value;
    setDate(moment(date).month(parseInt(selectedMonth, 10)));
  };

  const years = React.useMemo(() => {
    const currentYear = moment().year();
    return Array.from({length: currentYear - 2023 + 1}, (_, i) => (2023 + i).toString());
  }, []);

  const months = React.useMemo(() => {
    const currentYear = moment().year();
    const currentMonth = moment().month();
    const selectedYear = date.year();
    return moment.months().map((month, index) => ({
      name: month,
      value: index.toString(),
      disabled: selectedYear === currentYear && index > currentMonth,
    }));
  }, [date]);

  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 3}}>
      <FormControl size="small" variant="standard">
        <Select
          autoWidth
          value={date.year().toString()}
          onChange={handleYearChange}
          sx={{
            '& .MuiSelect-select': {
              padding: 0,
              '&:focus': {backgroundColor: 'transparent'},
            },
            '&:before, &:after': {display: 'none'},
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showMonthSelect && (
        <FormControl size="small" variant="standard">
          <Select
            autoWidth
            value={date.month().toString()}
            onChange={handleMonthChange}
            sx={{
              '& .MuiSelect-select': {
                padding: 0,
                '&:focus': {backgroundColor: 'transparent'},
              },
              '&:before, &:after': {display: 'none'},
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value} disabled={month.disabled}>
                {month.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default DateSelect;
