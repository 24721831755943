// ----- Modules ----- //
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
// ----- Contexts ----- //
import { PurchaseOrdersContext, PurchaseOrdersProvider } from "../../contexts/PurchaseOrdersProvider";
// ----- Components ----- //
import MpvOrders from "./components/mpv/MpvOrders";
import MessiOrders from "./components/tm/MessiOrders";
// ----- Types ----- //
import { Order } from "./types";

const PurchaseOrders: React.FC = () => {
  const {getMessiOrders, getMpvOrders, messiLoading, mpvLoading} = useContext(PurchaseOrdersContext);

  const [messiRows, setMessiRows] = useState<Order[]>([]);
  const [mpvRows, setMpvRows] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const [messiResponse, mpvResponse] = await Promise.all([getMessiOrders(), getMpvOrders()]);
      setMessiRows(messiResponse.orders);
      setMpvRows(mpvResponse.orders);
    } catch (error: any) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  }, [getMessiOrders, getMpvOrders]);
  
  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <Grid container>
      <MessiOrders rows={messiRows} loading={loading || messiLoading} fetchOrders={fetchOrders}/>
      <MpvOrders rows={mpvRows} loading={loading || mpvLoading} fetchOrders={fetchOrders}/>
    </Grid>
  );
};

const PurchaseOrdersWrapper: React.FC = () => (
  <PurchaseOrdersProvider>
    <PurchaseOrders/>
  </PurchaseOrdersProvider>
);

export default PurchaseOrdersWrapper;
