import React from "react";
import { LicenseInfo } from "@mui/x-license";
import { createRoot } from 'react-dom/client';

import "./index.css";
import App from "./App";

LicenseInfo.setLicenseKey(
  "0b18f155ab2adb3b1055055465bf04b4Tz03Mzk1NCxFPTE3MjUyODg1ODUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);
