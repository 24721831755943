import { Box, Divider, Drawer, IconButton, List, Paper, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useContext, useState } from "react";
import Navigation from "./Header/Navigation";
import { NavContext } from "../contexts/NavProvider";
import useMediaQuery from '@mui/material/useMediaQuery';

const Header: React.FC = () => {
  const {tab, tabs} = useContext(NavContext);
  const isMobile = useMediaQuery('(max-width:1500px)');
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => setDrawerOpen((prevOpen) => !prevOpen);

  return (
    <Paper
      sx={{
        py: 1,
        px: {xs: 2, md: 5},
        borderRadius: 0,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        height: '60px',
      }}
      elevation={3}
    >
      <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
        <Typography variant="h4" fontFamily="Tahoma">
          GETZLAF
        </Typography>
        <Typography
          color="text.secondary"
          sx={{mt: 'auto', ml: 0.5, display: {xs: 'none', md: 'block'}}}
        >
          Invoices Manager
        </Typography>
        <Divider
          orientation="vertical"
          sx={{mx: 3, height: '40px', display: {xs: 'none', xl: 'block'}}}
        />

        {isMobile ? (
          <>
            <IconButton
              onClick={handleDrawerToggle}
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ml: 'auto'}}
            >
              <MenuIcon/>
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
              <List sx={{width: 300, p: 2}}>
                <Navigation direction="column" icon/>
                <Divider sx={{my: 3}}/>
                {tabs[tab].info && (
                  <Paper
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: 1,
                      p: 1.5,
                    }}
                    elevation={3}
                  >
                    {tabs[tab].info}
                  </Paper>
                )}
              </List>
            </Drawer>
          </>
        ) : (
          <>
            <Navigation direction="row"/>
            <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end'}}>
              <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                {tabs[tab].info}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default Header;
