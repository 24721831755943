// ----- Modules ----- //
import React, { useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
// ----- MUI ----- //
// ----- Components ----- //
import { ConfirmDeleteModal, ConfirmPOModal } from "../OrderModal";
import OrderActions from "../OrderAction";
import CardComponent from "../../../../components/CardComponent";
// ----- Utils ----- //
import { CustomDataGrid } from "../../../../utils/Theme";
import { useConfiguredAxios } from "../../../../utils/AxiosInstance";
import { useSnackbar } from "notistack";
import { ValueFormatter } from "../../../../utils/ValueFormatter";
// ----- Types ----- //
import { Order } from "../../types";

interface MessiOrdersProps {
  rows: Order[];
  loading: boolean;
  fetchOrders: () => void;
}

const MessiOrders: React.FC<MessiOrdersProps> = ({rows, loading, fetchOrders}) => {
  const axiosInstance = useConfiguredAxios();
  const {enqueueSnackbar} = useSnackbar();

  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [confirmPOModalOpen, setConfirmPOModalOpen] = useState<boolean>(false);
  const [fetchingRawOrderData, setFetchingRawOrderData] = useState<string | null>(null);

  const handleDeleteClick = (order: Order) => {
    setSelectedOrder(order);
    setDeleteModalOpen(true);
  };

  const handleRawOrderDataClick = async (order: Order) => {
    setFetchingRawOrderData(order.id);

    await axios.post(`https://mcdavidalb.plessinc.com/purchases/parsed/orderdata/${order.id}`)
      .then(() => fetchOrders()).catch((error) => {
        enqueueSnackbar("Unable to fetch the data. Our process will try and log into the account. Please try again later.", {
          variant: "error",
          autoHideDuration: 10000
        });
        console.error("Error fetching raw order data:", error);
      });

    setFetchingRawOrderData(null);
  };

  const handlePOClick = (order: Order) => {
    setSelectedOrder(order);
    setConfirmPOModalOpen(true);
  };

  const handleDeleteOrder = async (orderId: string) => {
    try {
      await axiosInstance.delete(`/api/orders/messi/${orderId}`);
      console.log("Deleted order:", orderId);
    } catch (error: any) {
      console.error("Error deleting order:", error);
    }
    fetchOrders();
  };

  const handlePOOrder = async (orderId: string) => {
    try {
      await axiosInstance.post(`https://mcdavidalb.plessinc.com/purchases/parsed/po/${orderId}`)
        .then(() => fetchOrders()).catch((error) => console.error("Error processing PO order:", error));
    } catch (error: any) {
      console.error("Error processing PO order:", error);
    }
    fetchOrders();
  };

  const columns = useMemo(() => [
    {field: "order_ref", headerName: "REF", width: 125},
    {
      field: "is_ds", headerName: "DS", width: 75,
      renderCell: (params: any) => ValueFormatter.boolean(params.value),
      filterable: false,
    },
    {field: "email", headerName: "Email", width: 300},
    {field: "raw_order_data", headerName: "Raw Order Data", width: 250},
    {
      field: "date",
      headerName: "Purchase date",
      width: 175,
      valueGetter: (value: any) => moment(value).format("MM/DD/YYYY - hh:mm A"),
      filterable: false,
    },
    {field: "event_id", headerName: "Event ID", width: 200},
    {field: "vividseats_id", headerName: "Vividseats ID", width: 125},
    {
      field: "created_at",
      headerName: "Created At",
      width: 150,
      valueGetter: (params: any) => moment(params.value).format("MM/DD/YYYY"),
      filterable: false,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 150,
      valueGetter: (params: any) => moment(params.value).format("MM/DD/YYYY"),
      filterable: false,
    },
    {
      field: "actions",
      headerName: "",
      width: 125,
      renderCell: (params: any) => (
        <OrderActions
          order={params.row}
          onDelete={handleDeleteClick}
          onPO={handlePOClick}
          onFetchRawData={handleRawOrderDataClick}
          fetchingRawData={fetchingRawOrderData === params.row.id}
        />
      ),
      filterable: false,
    },
  ], [handleDeleteClick, handlePOClick, handleRawOrderDataClick, fetchingRawOrderData]);

  return (
    <>
      <CardComponent title="TM Orders" color="#086ce4" defaultHeight="calc(100vh - 200px)">
        <CustomDataGrid
          // @ts-ignore
          columns={columns}
          rows={rows}
          loading={loading}
          density="compact"
          initialState={{pinnedColumns: {right: ["actions"]}}}

          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
              noRowsVariant: 'skeleton',
            },
          }}

          headerFilters
        />
      </CardComponent>
      {selectedOrder && (
        <ConfirmDeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          orderId={selectedOrder.id}
          onDelete={handleDeleteOrder}
          market={selectedOrder.market}
        />
      )}
      {selectedOrder && (
        <ConfirmPOModal
          open={confirmPOModalOpen}
          onClose={() => setConfirmPOModalOpen(false)}
          orderId={selectedOrder.id}
          onPO={handlePOOrder}
        />
      )}
    </>
  );
};

export default MessiOrders;
