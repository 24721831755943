// ----- Modules ----- //
import React, { useCallback } from "react";
// ----- MUI ----- //
import { Box, Skeleton, Typography } from "@mui/material";
// ----- Animations ----- //
import { animated, useSpring } from "react-spring";

// ----- Types ----- //
type InfoTextProps = {
  value: number | null;
  valueType: 'currency' | 'number';
  label: string;
  icon: React.ReactNode;
};

export const InfoText: React.FC<InfoTextProps> = ({value, valueType, label, icon}) => {
  const valueFormatter = useCallback(
    (val: number | null) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(val || 0),
    []
  );

  const formatValue = (val: number) => {
    switch (valueType) {
      case 'currency':
        return valueFormatter(val);
      case 'number':
        return Math.round(val).toLocaleString();
      default:
        return val.toString();
    }
  };

  const springProps = useSpring({
    from: {number: 0},
    to: {number: value || 0},
    config: {duration: 500 + Math.random() * 500},
  });

  return (
    <Box sx={{pt: 1, display: 'flex', alignItems: 'center'}}>
      <Box sx={{color: 'text.secondary', pr: 0.5}}>
        {icon}
      </Box>
      <Box>
        {value === null ? (
          <Skeleton width={75} height={30} variant="text" sx={{mb: -0.5, mt: -0.75}}/>
        ) : (
          <Typography variant="h5" fontSize={20} color="info.main" fontWeight="bold" lineHeight={1}>
            <animated.span>
              {springProps.number.to(val => formatValue(val))}
            </animated.span>
          </Typography>
        )}
        <Typography variant="h6" fontSize={12} color="text.secondary" fontWeight="bold">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};
