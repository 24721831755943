// ----- Modules ----- //
import React, { useCallback, useEffect, useState } from 'react';
import { Moment } from "moment";
// ----- MUI ----- //
import { Badge, Box, Button, IconButton, LinearProgress, Modal, Tooltip, Typography } from '@mui/material';
// ----- MUI X ----- //
import { GridColDef } from '@mui/x-data-grid-premium';
import { DateRange } from "@mui/x-date-pickers-pro";
// ----- Utils ----- //
import { useConfiguredAxios } from "../../utils/AxiosInstance";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { enqueueSnackbar } from "notistack";
import { PaymentLineType } from "../../utils/Types";
import { CustomDataGrid, ModalStyle } from '../../utils/Theme';
import { ValueFormatter } from "../../utils/ValueFormatter";
import CheckIcon from "@mui/icons-material/Check";

interface PaymentWarningsProps {
  marketId: number;
  dateRange?: DateRange<Moment>;
}

const PaymentWarnings: React.FC<PaymentWarningsProps> = ({marketId, dateRange}) => {
  const axiosInstance = useConfiguredAxios();

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [warningsData, setWarningsData] = useState<PaymentLineType[]>([]);

  const confirmLine = useCallback(async (id: number) => {
    try {
      await axiosInstance.post(`/api/payments/lines/confirm/${id}`);
      enqueueSnackbar('Payment confirmed successfully.', {variant: 'success'});
      const updatedWarnings = warningsData.filter((line) => line.id !== id);
      setWarningsData(updatedWarnings);
    } catch (error) {
      enqueueSnackbar('Failed to check for payment warnings.', {variant: 'error'});
    }
  }, [marketId, warningsData]);


  const checkForWarnings = useCallback(async () => {
    try {
      if (!dateRange) return;
      const response = await axiosInstance.get(`/api/payments/haveWarnings`, {
        params: {
          marketId,
          from: dateRange[0]?.format('YYYY-MM-DD'),
          to: dateRange[1]?.format('YYYY-MM-DD'),
        },
      });
      setShowWarning(response.data);
    } catch (error) {
      enqueueSnackbar('Failed to check for payment warnings.', {variant: 'error'});
    }
  }, [marketId]);

  const fetchWarnings = useCallback(async () => {
    try {
      if (!dateRange) return;
      setLoading(true);
      const response = await axiosInstance.get(`/api/payments/warnings`, {
        params: {
          marketId,
          from: dateRange[0]?.format('YYYY-MM-DD'),
          to: dateRange[1]?.format('YYYY-MM-DD'),
        },
      });
      setWarningsData(response.data);
      setLoading(false);
      setModalOpen(true);
    } catch (error) {
      enqueueSnackbar('Failed to fetch unconfirmed payments.', {variant: 'error'});
      setLoading(false);
    }
  }, [marketId]);


  useEffect(() => {
    checkForWarnings();
  }, [checkForWarnings]);

  const handleClick = () => {
    if (showWarning) {
      fetchWarnings();
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'action', headerName: '', width: 55,
      renderCell: (params: any) => (
        <IconButton
          size="small"
          onClick={() => confirmLine(params.row.id)}
          color="success"
          title="Confirm payment"
          disabled={params.row.account !== 'LONG'}
        >
          <CheckIcon/>
        </IconButton>
      ),
    },
    {field: 'order_id', headerName: 'REF', width: 100},
    {field: 'payment_id', headerName: 'Payment ID', width: 125},
    {
      field: 'payment_date', headerName: 'Payment Date', width: 150,
      valueGetter: (value) => ValueFormatter.date(value, true)
    },
    {
      field: 'amount', headerName: 'Amount', width: 100, type: 'number',
      valueGetter: (value) => ValueFormatter.money(value)
    },
    {field: 'account', headerName: 'Account', width: 100},
    {field: 'type', headerName: 'Type', width: 150},
    {field: 'description', headerName: 'Description', width: 400},
  ];

  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      {loading && <LinearProgress sx={{width: '100px', mr: 1}}/>}
      <Tooltip title={
        showWarning ?
          'Show warnings'
          : 'No warnings found for this month'
      } placement={'top'}>
        <span>
          <IconButton
            color="warning"
            aria-label="view payment warnings"
            onClick={handleClick}
            disabled={loading || !showWarning}
          >
            <Badge color="error" variant="dot" invisible={!showWarning}>
              <WarningAmberIcon fontSize="small"/>
            </Badge>
          </IconButton>
        </span>
      </Tooltip>

      <Modal open={modalOpen} onClose={handleCloseModal} style={{backdropFilter: "blur(2px)"}}>
        <Box sx={{...ModalStyle, width: '90%', maxWidth: '1200px'}}>
          <Box sx={{display: "flex", alignItems: "center", marginY: 2}}>
            <WarningAmberIcon sx={{fontSize: '35px'}}/>
            <Typography variant="h4">Verify payment lines</Typography>
          </Box>
          <CustomDataGrid
            rows={warningsData}
            columns={columns}
            density="compact"
            sx={{height: 500}}
          />
          <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
            <Button variant="contained" color="primary" onClick={handleCloseModal}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PaymentWarnings;
